import type { CSSProperties } from 'react';

import type { BaseChartProps } from 'types';
import { CustomPieChart } from './style';

interface PieChartProps extends Omit<BaseChartProps, 'xCategories'> {
  colors?: CSSProperties['color'][];
  categories: string[];
}

function PieChart(props: PieChartProps) {
  const {
    categories,
    data,
    colors = [
      '#E6F7FF',
      '#91D5FF',
      '#40A9FF',
      '#096DD9',
      '#0050B3',
      '#003A8C',
      '#002766',
    ],
  } = props;
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'pie',
      selection: { enabled: false },
      animations: {
        enabled: false,
      },
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } },
    },
    series: data,
    colors,
    labels: categories,
    legend: {
      offsetY: -20,
      fontSize: '10px',
      fontWeight: 500,
      itemMargin: {
        vertical: 13,
      },
      onItemHover: { highlightDataSeries: false },
      onItemClick: { toggleDataSeries: false },
      markers: {
        shape: 'square',
      },
    },
    tooltip: {
      enabled: false,
    },
    stroke: { width: 0 },
  };

  return <CustomPieChart options={options} series={data} type="pie" />;
}

export default PieChart;
