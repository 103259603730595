import { Form, Input, message } from 'antd';
import { ConfirmModal } from 'components';
import type { ErrorType } from 'hooks/useErrors';
import { useErrors } from 'hooks/useErrors';
import { useTranslation } from 'react-i18next';
import { updateConversation } from 'services/conversation.service';
import type { IConversation, RenameConversationReqDTO } from 'types';

interface RenameModalProps {
  open: boolean;
  toggleOpen: (val: boolean) => void;
  item: IConversation;
  refetch: (id?: number) => Promise<void>;
}

export function RenameModal({
  open,
  toggleOpen,
  item,
  refetch,
}: RenameModalProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm<RenameConversationReqDTO>();
  const { mapToFormErrors } = useErrors();
  const handleFinish = async (values: RenameConversationReqDTO) => {
    try {
      await updateConversation(item.id, {
        conversation_name: values.conversation_name,
      });
      toggleOpen(false);
      await refetch();
      message.success(t('Rename conversation successfully'));
    } catch (error) {
      form.setFields(mapToFormErrors(error as ErrorType));
      message.error(t('Rename conversation unsuccessfully'));
    }
  };

  return (
    <ConfirmModal
      handleOk={() => form.submit()}
      isModalOpen={open}
      setIsModalOpen={toggleOpen}
      title={t('Rename conversation')}
      destroyOnClose
      content={
        <Form form={form} preserve={false} onFinish={handleFinish}>
          <Form.Item<RenameConversationReqDTO>
            name="conversation_name"
            initialValue={item.conversation_name}
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input maxLength={100} />
          </Form.Item>
        </Form>
      }
      okBtn={t('Save')}
    />
  );
}
