import type { TableColumnsType, UploadFile } from 'antd';
import { Alert, Flex, message, Space, Spin, Typography, Upload } from 'antd';
import {
  ConfirmModal,
  CustomModal,
  OutlineButton,
  SolidBtn,
  TextButton,
} from 'components';
import { CustomTable } from 'components/CustomTable';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  downloadFileByType,
  uploadFileByType,
} from 'services/terminologyManagement.service';
import { CustomProgress } from 'components/CustomProgress';
import FileIcon from 'assets/icons/file-icon.png';
import Arrowup from 'assets/icons/arrow-up.png';
import type { TerminologyType } from 'types/terminology';

enum AcceptedFileType {
  CSV = 'csv',
  XLS = 'xls',
  XLSX = 'xlsx',
}

interface UploadModalProps {
  accept?: (keyof typeof AcceptedFileType)[];
  toggleOpen: (value: boolean) => void;
  open: boolean;
  termType: TerminologyType;
  columns: TableColumnsType;
  refetch: () => Promise<void>;
}

export function UploadModal(props: UploadModalProps) {
  const { toggleOpen, open, accept, termType, columns, refetch } = props;
  const { t } = useTranslation();
  const [isConfirmCancelModalOpen, toggleConfirmCancelModal] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);

  const [progress, setProgress] = useState<number | null>(null);

  const [file, setFile] = useState<UploadFile | null>(null);
  const [showError, toggleShowError] = useState(false);
  const [duplicatedRecords, setDuplicatedRecords] = useState([]);

  function resetStates() {
    setFile(null);
    toggleShowError(false);
    setDuplicatedRecords([]);
    setIsUploading(false);
    setProgress(null);
    setIsUploading2(false);
  }

  useEffect(
    () => () => {
      resetStates();
    },
    [open],
  );

  const handleCancel = () => {
    if (file) toggleConfirmCancelModal(true);
    else toggleOpen(false);
  };

  const uploadCheckDuplicated = async () => {
    if (!file) return;
    setIsUploading(true);
    await uploadFileByType(termType, file, false, ({ percent }) => {
      setProgress(percent as never);
    })
      .then(async (res) => {
        toggleOpen(false);
        await refetch();
        message.success({ content: t('Terminologies imported successfully') });
        setIsUploading(false);

        return res;
      })
      .catch((err) => {
        console.log(err);
        setIsUploading(false);
        if (err.duplicated) {
          setDuplicatedRecords(err.duplicated);

          return;
        }

        toggleShowError(true);
        setProgress(0);
        message.error({
          content: t('Terminologies imported unsuccessfully'),
        });
      });
  };

  const uploadSkipDuplicated = async () => {
    if (!file) return;
    setIsUploading2(true);
    await uploadFileByType(termType, file, true, ({ percent }) => {
      setProgress(percent as never);
    })
      .then(async (res) => {
        toggleOpen(false);
        await refetch();
        message.success({ content: t('Terminologies imported successfully') });
        setIsUploading2(false);

        return res;
      })
      .catch((err) => {
        console.log(err);
        setIsUploading2(false);
        message.error({ content: t('Terminologies imported unsuccessfully') });
      });
  };

  const handleDownload = () => {
    downloadFileByType(termType)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
          'download',
          `${termType}_Terminologies_Template.xlsx`,
        );

        document.body.appendChild(link);
        link.click();

        link.parentNode?.removeChild(link);

        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPrimaryButton = () => {
    if (showError) {
      return (
        <SolidBtn
          type="primary"
          style={{ padding: '8px 20px' }}
          onClick={() => resetStates()}
        >
          {t('Choose another file')}
        </SolidBtn>
      );
    }

    if (duplicatedRecords.length > 0) {
      return (
        <SolidBtn
          type="primary"
          style={{ padding: '8px 20px' }}
          onClick={uploadSkipDuplicated}
        >
          {t('Skip duplicated data')}
        </SolidBtn>
      );
    }

    return (
      <SolidBtn
        type="primary"
        style={{ padding: '8px 20px' }}
        onClick={uploadCheckDuplicated}
        disabled={!file}
      >
        {t('Upload')}
      </SolidBtn>
    );
  };

  const getFooter = () => {
    if (isUploading) return null;

    if (duplicatedRecords.length > 0)
      return <Flex justify="end">{getPrimaryButton()}</Flex>;

    return (
      <Flex justify="space-between">
        <TextButton onClick={handleDownload}>
          {t('Download template')}
        </TextButton>
        <Space style={{ marginLeft: 'auto' }}>
          <OutlineButton onClick={handleCancel}>{t('Cancel')}</OutlineButton>
          {getPrimaryButton()}
        </Space>
      </Flex>
    );
  };

  const acceptList = accept
    ? accept.map((item) => `.${AcceptedFileType[item]}`).join(',')
    : '*';

  const getContent = () => {
    if (progress !== null && file)
      return <CustomProgress fileName={file?.name} percent={progress} />;
    if (file) {
      return (
        <Alert
          icon={<img src={FileIcon} alt="file-icon" />}
          showIcon
          style={{
            width: 'fit-content',
            borderColor: '#69C0FF',
            padding: '12px 16px',
            margin: 'auto',
          }}
          message={
            <Typography.Text style={{ fontWeight: 500, fontSize: 16 }}>
              {file.name}
            </Typography.Text>
          }
          type="info"
          closable
          onClose={() => setFile(null)}
        />
      );
    }

    return (
      <Flex vertical justify="center" align="center" gap={16}>
        <Upload
          showUploadList={false}
          accept={acceptList}
          onChange={(e) => setFile(e.file)}
        >
          <OutlineButton>{t('Select file')}</OutlineButton>
        </Upload>
        <Typography.Text>
          {t('Drag and drop data file or select from device')}
        </Typography.Text>
        <img src={Arrowup} alt="arrow-up" />
      </Flex>
    );
  };

  return (
    <CustomModal
      {...props}
      onCancel={handleCancel}
      onClose={handleCancel}
      title={t('Importing data')}
      footer={null}
      width={600}
      destroyOnClose
    >
      <Spin spinning={isUploading2}>
        <Flex vertical gap={24}>
          <Typography.Text style={{ textAlign: 'center' }}>
            {t(
              'Please update the upload file to .xlxs or csv format before uploading data. Maximum upload file size is 5MB',
            )}
          </Typography.Text>
          <Upload.Dragger
            openFileDialogOnClick={false}
            style={{
              cursor: 'default',
              borderColor: '#69c0ff',
              backgroundColor: 'f5f5f5',
              borderRadius: 16,
              padding: '32px 64px',
            }}
            accept={acceptList}
            showUploadList={false}
            onChange={(e) => setFile(e.file)}
          >
            {getContent()}
          </Upload.Dragger>
          {showError && (
            <Typography.Text style={{ textAlign: 'center', color: '#F5222D' }}>
              {t('The importing file does not match the system’s requirements')}
            </Typography.Text>
          )}
          {duplicatedRecords.length > 0 && (
            <CustomTable
              scroll={{ y: 240 }}
              dataSource={duplicatedRecords}
              columns={columns}
              rowKey="id"
              className="custom-table"
              pagination={false}
            />
          )}
          {getFooter()}
        </Flex>
      </Spin>

      <ConfirmModal
        width={500}
        content={t(
          'All importing data from file {{fileName}} update will not be saved into system. Are you sure to continue cancel the process?',
          { fileName: file?.name },
        )}
        handleOk={() => {
          toggleConfirmCancelModal(false);
          resetStates();
          toggleOpen(false);
        }}
        handleCancel={() => toggleConfirmCancelModal(false)}
        setIsModalOpen={toggleConfirmCancelModal}
        title={t('Cancel importing data')}
        okBtn={t('Continue')}
        isModalOpen={isConfirmCancelModalOpen}
      />
    </CustomModal>
  );
}
