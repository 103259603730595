import type { IParam, ITerminology } from 'types';
import { axiosInstance } from '../utils/axios';

export const createTerminology = (
  params: Pick<ITerminology, 'terminology_name' | 'description'>,
) => axiosInstance.post<never, ITerminology>('/api/terminology', params);

export const getTerminologies = (params?: IParam) =>
  axiosInstance.get<never, { terminology_names: string[] }>(
    '/api/terminology',
    {
      params,
    },
  );

export const getTerminology = ({ id }: Pick<ITerminology, 'id'>) =>
  axiosInstance.get<never, ITerminology[]>(`/api/terminology/${id}`);

export const updateTerminology = (
  params: Pick<ITerminology, 'id' | 'terminology_name'>,
) =>
  axiosInstance.put<never, ITerminology>(
    `/api/terminology/${params.id}`,
    params,
  );

export const deleteTerminology = (id: number) =>
  axiosInstance.delete<never, ITerminology>(`/api/terminology/${id}`);
