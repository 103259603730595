import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { ConfigProvider } from 'antd';
import i18next from 'i18next';

import { en } from './configs/languages/en';
import { vn } from './configs/languages/vn';
import { themeConfig } from './configs/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// i18next.init({
//   interpolation: { escapeValue: false },  // React already does escaping
// });

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en,
      },
      vn: {
        translation: vn,
      },
    },
    lng: 'vn', // if you're using a language detector, do not define the lng option
    fallbackLng: 'vn',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

root.render(
  <React.StrictMode>
    <ConfigProvider theme={themeConfig}>
      {/* <I18nextProvider i18n={i18next}> */}
      <App />
      {/* </I18nextProvider> */}
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
