import type { ModalProps } from 'antd';
import { Flex, Form, Input, message, Spin } from 'antd';
import { CustomModal } from 'components/Modals';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { UpdatePasswordReqDTO } from 'types/dto/requests/user';
import { updateUserPassword } from 'services/userProfile.service';
import { passwordRegex } from 'constants/regex';
import { CustomForm } from '../style';

type UpdatePasswordForm = UpdatePasswordReqDTO & {
  confirm_password: string;
};

interface ResetPasswordModalProps extends Pick<ModalProps, 'open'> {
  toggleOpen: (value: boolean) => void;
}

export function ResetPasswordModal({
  open,
  toggleOpen,
}: ResetPasswordModalProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => toggleOpen(false);
  const handleCancel = () => toggleOpen(false);

  const handleFinish = (formValues: unknown) => {
    const values = formValues as UpdatePasswordForm;

    updateUserPassword({
      new_password: values.new_password,
      old_password: values.old_password,
    })
      .then((res) => {
        setIsLoading(false);
        message.success({
          content: t('Update password successfully'),
        });
        toggleOpen(false);

        return res;
      })
      .catch(() => {
        setIsLoading(false);
        form.setFields([
          {
            name: 'old_password',
            errors: [t('The old password is incorrect')],
          },
        ]);
        message.error({
          content: t('Update password unsuccessfully'),
        });
      });
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <CustomModal
      open={open}
      centered
      title={t('Reset password')}
      width={600}
      onCancel={handleCancel}
      onClose={handleClose}
      okText={t('Confirm')}
      onOk={handleOk}
      destroyOnClose
    >
      <Spin spinning={isLoading}>
        <Flex vertical gap={24}>
          <div className="body">
            <CustomForm
              form={form}
              name="update-user-information"
              layout="vertical"
              preserve={false}
              onFinish={handleFinish}
            >
              <CustomForm.Item<UpdatePasswordForm>
                label={t('Current password')}
                name="old_password"
                required
                rules={[
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                ]}
              >
                <Input.Password />
              </CustomForm.Item>
              <CustomForm.Item<UpdatePasswordForm>
                label={t('New password')}
                name="new_password"
                required
                rules={[
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                  {
                    pattern: passwordRegex,
                    message: t('Invalid password field'),
                  },
                ]}
              >
                <Input.Password />
              </CustomForm.Item>
              <CustomForm.Item<UpdatePasswordForm>
                label={t('Retype new password')}
                name="confirm_password"
                dependencies={['new_password']}
                required
                rules={[
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value)
                        return Promise.resolve();

                      return Promise.reject(
                        new Error(
                          t('The new password that you entered do not match!'),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </CustomForm.Item>
            </CustomForm>
          </div>
        </Flex>
      </Spin>
    </CustomModal>
  );
}
