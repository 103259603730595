import type { LanguageType } from 'types';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface ILangState {
  lang: LanguageType;
  changeLang: (lang: LanguageType) => void;
}

export const useLangStore = create<ILangState>()(
  devtools(
    persist(
      (set) => ({
        lang: 'en',
        changeLang: (lang) => set({ lang }),
      }),
      {
        name: 'lang-storage',
      },
    ),
  ),
);
