import { Button, Card, Flex, Space } from 'antd';
import type { IGuideline } from 'types';
import type { Editor, EventInfo } from 'ckeditor5';
import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify';
import { TextEditor } from './TextEditor';

interface GuideItemProps {
  item: IGuideline;
  isEdit?: boolean;
  onChange: (val: string, index: number) => void;
  onDelete: (index: number) => void;
  index: number;
}

export function GuideItem({
  item,
  onChange,
  onDelete,
  isEdit = false,
  index,
}: GuideItemProps) {
  const { content } = item;

  const [showDeleteIcon, toggleDeleteIcon] = useState(false);

  const handleChange: (event: EventInfo, editor: Editor) => void = (
    _,
    editor,
  ) => {
    const value = editor.getData().trim();

    onChange(value, index);
  };

  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <Card
      style={{
        borderColor: '#C2EDFF',
        borderRadius: 20,
        position: 'relative',
        backgroundColor: '#FAFAFA',
      }}
      onMouseEnter={() => toggleDeleteIcon(true)}
      onMouseLeave={() => toggleDeleteIcon(false)}
    >
      {showDeleteIcon && isEdit && (
        <Button
          style={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            transform: 'translate(-20%, -20%)',
            borderRadius: '50%',
            zIndex: 99,
          }}
          type="default"
          icon={<DeleteOutlined />}
          onClick={() => onDelete(index)}
        />
      )}
      <Space size="middle">
        <Flex
          style={{
            border: '7px solid #C2EDFF',
            background:
              'linear-gradient(228.62deg, #36C3FC -0.24%, #009FDE 99.37%)',
            width: 36,
            height: 36,
            borderRadius: 100,
            padding: 4,
            fontWeight: 700,
            fontSize: 14,
            color: 'white',
          }}
          align="center"
          justify="center"
        >
          {index}
        </Flex>
        {isEdit ? (
          <TextEditor onChange={handleChange} value={sanitizedContent} />
        ) : (
          // eslint-disable-next-line react/no-danger
          <span dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        )}
      </Space>
    </Card>
  );
}
