import type { CSSProperties } from 'react';
import Chart from 'react-apexcharts';

import { useTranslation } from 'react-i18next';
import { useLangStore } from 'stores';
import { MeasurementMap } from 'pages/Chat/constants';
import type { BaseChartProps } from 'types';
import type { ApexOptions } from 'apexcharts';
import { extractColumnFromCountColumn } from 'pages/Chat/utils';
import type { ApexChartsTitleStyle } from '../types';

interface BarChartProps extends Omit<BaseChartProps, 'data'> {
  color?: CSSProperties['color'];
  xUnit?: string;
  yUnit?: string;
  yUnit2?: string;
  language?: string;
  data: number[][];
}

function BarChart(props: BarChartProps) {
  const {
    xCategories,
    data,
    color = '#40A9FF',
    xUnit,
    yUnit,
    yUnit2,
    language,
  } = props;
  const { t } = useTranslation();
  const { lang } = useLangStore();

  const lng = language ?? lang;

  const xAxisTitle = xUnit && t(`${xUnit}`, { lng });

  const yUnitExtracted = extractColumnFromCountColumn(yUnit);
  const yUnit2Extracted = extractColumnFromCountColumn(yUnit2);

  const yAxisTitle =
    yUnit &&
    MeasurementMap[yUnitExtracted] &&
    t(MeasurementMap[yUnitExtracted], { lng });
  const yAxisTitle2 =
    yUnit2 &&
    MeasurementMap[yUnit2Extracted] &&
    t(MeasurementMap[yUnit2Extracted], { lng });

  const titleStyle: ApexChartsTitleStyle = {
    fontSize: '10px',
    fontWeight: 'bold',
  };

  const optimalColumnWidthPercent =
    20 + 60 / (1 + 30 * Math.exp(-data.length / 3));

  const getColumnWidth = () => {
    if (data[0].length * data.length < 20) return optimalColumnWidthPercent;

    return '80%';
  };

  const xCatFormatted = xCategories[0].map((_, colIndex) =>
    xCategories.map((row) => row[colIndex]),
  );

  const yAxisArray: ApexOptions['yaxis'] = [
    {
      forceNiceScale: true,
      min: 0,
      labels: {
        style: {
          fontWeight: 500,
          fontSize: '10px',
          colors: yAxisTitle2 ? '#40A9FF' : 'inherit',
        },
      },
      ...(yUnit
        ? {
            title: {
              text: `(${yAxisTitle})`,
              style: titleStyle,
            },
          }
        : {}),
    },
  ];

  if (yAxisTitle2) {
    yAxisArray.push({
      opposite: true,
      forceNiceScale: true,
      min: 0,
      labels: {
        style: {
          fontWeight: 500,
          fontSize: '10px',
          colors: '#f62225',
        },
      },
      title: {
        text: `(${yAxisTitle2})`,
        style: titleStyle,
      },
    });
  }

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      zoom: { enabled: false },
      animations: {
        enabled: false,
      },
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } },
    },
    grid: {
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: getColumnWidth(),
      },
    },
    colors: [color, '#F5222D', '#3FA684'],
    dataLabels: {
      enabled: false,
    },
    ...(xAxisTitle
      ? {
          xaxis: {
            categories: xCatFormatted,
            axisTicks: { show: false },
            labels: {
              style: { fontWeight: 500, fontSize: '10px' },
              hideOverlappingLabels: false,
              trim: true,
              minHeight: 100,
              maxHeight: 150,
            },
            tooltip: {
              enabled: true,
              formatter(value) {
                const arr = value as never as string[];

                if (!arr) return '';

                return arr.join('</br>');
              },
            },
            ...(xUnit
              ? {
                  title: {
                    text: xAxisTitle,
                    style: titleStyle,
                  },
                }
              : {}),
          },
        }
      : {}),
    ...(yAxisTitle
      ? {
          yaxis: yAxisArray,
        }
      : {}),
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      marker: {},
      custom() {
        return null;
      },
    },
  };

  const series = data.map((item) => ({ data: item }));

  return <Chart options={options} series={series} type="bar" />;
}

export default BarChart;
