import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import type {
  PaginationProps,
  SelectProps,
  TableColumnsType,
  TablePaginationConfig,
} from 'antd';
import { Button, Input, notification, Select, Spin } from 'antd';

import { ConfirmModal, IconButton, OutlineButton, SolidBtn } from 'components';
import {
  deleteUser,
  getUserManagements,
} from 'services/userManagement.service';

import { getFullnameByLang } from 'utils';
import { useAuthStore } from 'stores';
import { CustomTable } from 'components/CustomTable';
import type { IParam, IUser } from 'types';
import AddEditUserModal from './AddEditUserModal';
import { UserManagementTab } from './style';

import { ResetPasswordModal } from './ResetPasswordModal';

function UserManagement({ activeTab }: { activeTab: string }) {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [isOpenResetConfirm, setIsOpenResetConfirm] = useState(false);
  const [isOpenAddEditModal, setIsOpenAddEditModal] = useState(false);
  const [listUser, setListUser] = useState<IUser[]>();
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [listSelectedUser, setListSelectedUser] = useState<IUser[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [contentDeleteModal, setContentDeleteModal] = useState<ReactNode>('');
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filter, setFilter] = useState<IParam>({ page: 1 });

  const columns: TableColumnsType<IUser> = [
    {
      title: 'No',
      dataIndex: 'no',
      width: 60,
      render: (_value: string, _record: IUser, index: number) => {
        const no = ((filter.page || 1) - 1) * 10 + index + 1;

        return (
          <div>
            <span className="pl-2">{no}</span>
          </div>
        );
      },
    },
    {
      title: t('Name'),
      render: (_value: string, record: IUser) =>
        getFullnameByLang(record.first_name, record.last_name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: t('Employee ID'),
      dataIndex: 'employee_id',
    },
    {
      title: t('Role'),
      dataIndex: 'user_role',
      render: (value: string) => (
        <span
          style={{
            textTransform: 'capitalize',
            color: value === 'admin' ? '#F5222D' : '#096DD9',
          }}
        >
          {value || 'User'}
        </span>
      ),
    },
    {
      title: t('Action'),
      width: 150,
      dataIndex: 'action',
      render: (_value: string, record: IUser) => (
        <>
          <IconButton
            onClick={() => {
              setSelectedUser(record);
              setIsOpenAddEditModal(true);
            }}
            icon={<EditOutlined style={{ fontSize: '20px' }} />}
            style={{ marginRight: '20px' }}
          />
          {user?.is_superuser && (
            <IconButton
              onClick={() => {
                const fullName = record.email;
                const content = (
                  <Trans
                    i18nKey="Are you sure to delete user"
                    values={{ name: fullName }}
                  >
                    Are you sure to delete user
                    <strong>{fullName}</strong>
                  </Trans>
                );

                setContentDeleteModal(content);
                setSelectedRowKeys([]);
                setListSelectedUser([record]);
                setIsOpenDeleteConfirm(true);
              }}
              icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
            />
          )}
        </>
      ),
    },
    {
      title: 'Date joined',
      dataIndex: 'date_joined',
      hidden: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const date1 = Number(new Date(a.date_joined));
        const date2 = Number(new Date(b.date_joined));

        return date1 - date2;
      },
    },
  ];

  useEffect(() => {
    setFilter({ page: 1 });
    setSelectedRowKeys([]);
  }, [activeTab]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (curSelectedRowKeys: React.Key[], selectedRows: IUser[]) => {
      setSelectedRowKeys(curSelectedRowKeys);
      setListSelectedUser(selectedRows);
    },
    selectedRowKeys,
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);

      const sanitizedFilter: IParam = {
        ...filter,
        search: filter?.search?.trim(),
      };
      const result = await getUserManagements(sanitizedFilter);

      setListUser(result.results);
      setTotal(result.count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    getData();
  }, [filter, getData]);

  const handleChangePage = (pagination: TablePaginationConfig) => {
    setFilter({
      ...filter,
      page: pagination.current ?? 1,
    });
  };

  const options: SelectProps['options'] = [
    {
      value: '',
      label: t('All'),
    },
    {
      value: 'admin',
      label: t('Admin'),
    },
    {
      value: 'user',
      label: t('User'),
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchText(value);
    setFilter({
      ...filter,
      search: value.trim(),
      page: 1,
    });
  };

  const handleChange = (value: string | string[]) => {
    setFilter({
      ...filter,
      role: value as string,
    });
  };

  const handleDelete = async () => {
    try {
      if (!listSelectedUser.length) return;

      await deleteUser(listSelectedUser.map((item) => item.id));
      getData();
      setIsOpenDeleteConfirm(false);
      notification.success({
        message: t('Delete successfully'),
      });
    } catch (error) {
      notification.error({
        message: t('Delete unsuccessfully'),
      });
    }
  };

  const handleItemRender: PaginationProps['itemRender'] = (
    _current,
    type,
    originalElement,
  ) => {
    if (type === 'prev') {
      return (
        <Button
          shape="circle"
          icon={<LeftOutlined style={{ fontSize: '12px' }} />}
        />
      );
    }

    if (type === 'next') {
      return (
        <Button
          shape="circle"
          icon={<RightOutlined style={{ fontSize: '12px' }} />}
        />
      );
    }

    return originalElement;
  };

  return (
    <UserManagementTab>
      <Spin spinning={isLoading} fullscreen />

      <div className="header">
        <div className="left-side">
          <Input
            size="large"
            placeholder={t('Search')}
            prefix={<SearchOutlined />}
            style={{ width: '200px', marginRight: '12px' }}
            onChange={handleSearch}
            value={searchText}
          />
          <Select
            value={filter.role || ''}
            placeholder={t('Role')}
            size="large"
            onChange={handleChange}
            style={{ width: '150px', marginRight: '12px' }}
            options={options}
          />
          <p
            style={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              textAlign: 'left',
              color: '#595959',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Total')}: {total} user(s)
          </p>
        </div>

        <div className="right-side">
          {user?.is_superuser && (
            <OutlineButton
              style={{ marginRight: '12px' }}
              onClick={() => setIsOpenDeleteConfirm(true)}
              disabled={!selectedRowKeys.length}
            >
              {t('Delete')}
            </OutlineButton>
          )}
          <SolidBtn
            type="primary"
            style={{ padding: '8px 20px' }}
            onClick={() => {
              setIsOpenAddEditModal(true);
              setSelectedUser(undefined);
            }}
          >
            <PlusOutlined />
            {t('Add new')}
          </SolidBtn>
        </div>
      </div>
      <CustomTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="id"
        className="custom-table"
        columns={columns}
        dataSource={listUser}
        onChange={handleChangePage}
        pagination={{
          position: ['bottomCenter'],
          total,
          pageSize: 10,
          itemRender: handleItemRender,
        }}
      />
      <ConfirmModal
        content={
          listSelectedUser.length === 1
            ? contentDeleteModal
            : t('Are you sure to delete all selected users?')
        }
        handleOk={handleDelete}
        setIsModalOpen={setIsOpenDeleteConfirm}
        title={t('Delete user')}
        okBtn={t('Delete')}
        isModalOpen={isOpenDeleteConfirm}
      />
      {selectedUser && (
        <ResetPasswordModal
          setOpen={setIsOpenResetConfirm}
          open={isOpenResetConfirm}
          user={selectedUser}
        />
      )}
      {isOpenAddEditModal && (
        <AddEditUserModal
          selectedUser={selectedUser}
          setIsModalOpen={setIsOpenAddEditModal}
          isModalOpen={isOpenAddEditModal}
          callbackSuccessfully={getData}
          resetCallback={() => {
            setIsOpenAddEditModal(false);
            setIsOpenResetConfirm(true);
          }}
        />
      )}
    </UserManagementTab>
  );
}

export default UserManagement;
