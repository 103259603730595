import { useTranslation } from 'react-i18next';
import type { FormProps, SelectProps } from 'antd';
import { Form, Input, notification, Select, Spin } from 'antd';

import { useState } from 'react';
import { createUser, updateUser } from 'services/userManagement.service';

import type { ErrorType } from 'hooks/useErrors';
import { useErrors } from 'hooks/useErrors';
import { OutlineButton, SolidBtn } from 'components/Buttons';
import { CustomModal } from 'components/Modals/CustomModal';
import type { IUser, UserRole } from 'types';
import { TextButton } from './style';
import { useAuthStore, useLangStore } from '../../../stores';

interface Props {
  isModalOpen: boolean;
  width?: number;
  selectedUser?: IUser;
  setIsModalOpen: (isModalOpen: boolean) => void;
  resetCallback: () => void;
  callbackSuccessfully: () => void;
}

type FieldType = {
  first_name: string;
  last_name: string;
  email: string;
  employee_id: string;
  user_role: UserRole;
};

function AddEditUserModal({
  isModalOpen,
  width = 600,
  selectedUser,
  setIsModalOpen,
  resetCallback,
  callbackSuccessfully,
}: Props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { lang } = useLangStore();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthStore();
  const { mapToFormErrors } = useErrors();
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      setIsLoading(true);

      if (selectedUser) {
        await updateUser({ ...values, id: selectedUser.id });
        notification.success({
          message: t('Save user successfully'),
        });
      } else {
        await createUser({ ...values, language: lang });
        notification.success({
          message: t('Create user successfully'),
        });
      }

      setIsLoading(false);
      setIsModalOpen(false);
      callbackSuccessfully();
    } catch (error) {
      setIsLoading(false);
      form.setFields(mapToFormErrors(error as ErrorType));

      notification.error({
        message: selectedUser
          ? t('Update user unsuccessfully')
          : t('Create user unsuccessfully'),
      });
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo,
  ) => {
    console.log('Failed:', errorInfo);
  };

  const options: SelectProps['options'] = [
    {
      value: 'admin',
      label: t('Admin'),
    },
    {
      value: 'user',
      label: t('User'),
    },
  ];

  return (
    <CustomModal
      centered
      footer={null}
      title={selectedUser ? t('Edit user information') : t('Add new user')}
      open={isModalOpen}
      width={width}
      onCancel={handleClose}
    >
      <Spin spinning={isLoading} fullscreen />
      <Form
        layout="vertical"
        form={form}
        name="basic"
        initialValues={selectedUser}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType> name="first_name" label={t('First Name')}>
          <Input
            maxLength={50}
            placeholder={`${t('Enter first name')}`}
            size="large"
            required={false}
          />
        </Form.Item>

        <Form.Item<FieldType> name="last_name" label={t('Last Name')}>
          <Input
            maxLength={50}
            placeholder={`${t('Enter last name')}`}
            size="large"
          />
        </Form.Item>

        <Form.Item<FieldType>
          name="email"
          label={t('Email')}
          required
          rules={[
            {
              required: true,
              whitespace: true,
              message: t('Please input email field!'),
            },
            { type: 'email', message: t('Invalid email field') },
          ]}
        >
          <Input
            placeholder={`${t('Enter email')}`}
            size="large"
            disabled={!!selectedUser?.email}
          />
        </Form.Item>

        <Form.Item<FieldType>
          name="employee_id"
          label={t('Employee ID')}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t('Please input employee ID field!'),
            },
          ]}
        >
          <Input
            placeholder={`${t('Enter employee ID')}`}
            maxLength={50}
            size="large"
            disabled={!!selectedUser?.employee_id}
          />
        </Form.Item>

        <Form.Item<FieldType> name="user_role" label={t('Role')}>
          <Select
            defaultValue="user"
            placeholder={t('Role')}
            size="large"
            options={options}
            disabled={!user?.is_superuser}
          />
        </Form.Item>
        <div className="footer">
          <div>
            {selectedUser && (
              <TextButton onClick={resetCallback}>
                {t('Reset password')}
              </TextButton>
            )}
          </div>
          <div>
            <OutlineButton
              style={{ marginRight: '12px' }}
              onClick={handleClose}
            >
              {t('Cancel')}
            </OutlineButton>
            <SolidBtn
              type="primary"
              htmlType="submit"
              style={{ padding: '8px 20px' }}
            >
              {selectedUser ? t('Save') : t('Create')}
            </SolidBtn>
          </div>
        </div>
      </Form>
    </CustomModal>
  );
}

export default AddEditUserModal;
