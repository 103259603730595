import { Link } from 'react-router-dom';
import { DoubleLeftOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Tabs } from 'antd';

import { useTranslation } from 'react-i18next';
import { RightSideHeader } from 'components';
import { TerminologyManagementWrapper } from './style';
import {
  LineManagement,
  ProcessManagement,
  ProductManagement,
  XRManagement,
} from './components';

function TerminologyManagementTabs() {
  const { t } = useTranslation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Line'),
      children: <LineManagement />,
    },
    {
      key: '2',
      label: t('Process'),
      children: <ProcessManagement />,
    },
    {
      key: '3',
      label: t('Product'),
      children: <ProductManagement />,
    },
    {
      key: '4',
      label: t('X-R'),
      children: <XRManagement />,
    },
  ];

  return (
    <TerminologyManagementWrapper>
      <div className="header">
        <div className="left-side">
          <Link to="/chat">
            <DoubleLeftOutlined
              style={{ fontSize: '24px', color: '#262626' }}
            />
          </Link>
          <span className="title">{t('Terminology management')}</span>
        </div>
        <div className="right-side">
          <RightSideHeader />
        </div>
      </div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        className="custom-tabs"
        destroyInactiveTabPane
      />
    </TerminologyManagementWrapper>
  );
}

export default TerminologyManagementTabs;
