import { Menu } from 'antd';
import styled from 'styled-components';

export const CustomMenu = styled(Menu)`
  .ant-menu-item-selected,
  .ant-menu-item-active {
    box-shadow: 0px 1px 6px -32px #0050891a;
    border: 0.5px solid #ffffff !important;
    transition: none;
    box-sizing: border-box;
  }
  .ant-menu-item {
    border: 0.5px solid transparent;
  }
`;
