import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Dropdown, Select, Typography } from 'antd';

import AvatarDefault from 'assets/icons/avatar-default.png';
import EnFlag from 'assets/icons/en-flag.png';
import VnFlag from 'assets/icons/vn-flag.png';
import { updateLang } from 'services/userManagement.service';
import { useAuthStore, useLangStore } from 'stores';

import type { LanguageType, LoginResDTO } from 'types';
import { GuideModal } from 'components/Modals';
import { RightSideHeaderWrapper } from './style';
import { EmailUpdateModal, UserUpdateModal } from './components';

export function RightSideHeader() {
  const { i18n, t } = useTranslation();
  const { changeLang, lang } = useLangStore();
  const { user, resetUser } = useAuthStore();

  const [isUserUpdateModalOpen, toggleUserUpdateModal] = useState(false);
  const [isGuideUpdateModalOpen, toggleGuideUpdateModal] = useState(false);
  const [isEmailUpdateModalOpen, toggleEmailUpdateModal] = useState(false);

  const onChange = (value: LanguageType) => {
    i18n.changeLanguage(value);
    changeLang(value);
  };

  const updateLanguage = useCallback(async () => {
    try {
      await updateLang(lang);
    } catch (error) {
      console.log('error', error);
    }
  }, [lang]);

  useEffect(() => {
    if (!user) return;
    updateLanguage();
  }, [updateLanguage, user]);

  const handleLogout = () => {
    resetUser();
  };

  const userItems: MenuProps['items'] = [
    {
      label: <Typography.Text>{t('Personal information')}</Typography.Text>,
      key: '1',
      onClick: () => toggleUserUpdateModal(true),
    },
    {
      label: <Typography.Text>{t('Guideline content')}</Typography.Text>,
      key: 'update-guide-content',
      onClick: () => toggleGuideUpdateModal(true),
    },
    {
      label: t('Log out'),
      key: '2',
      onClick: handleLogout,
      style: { color: 'red' },
    },
  ];

  const superAdminItems: MenuProps['items'] = [
    {
      label: <Link to="/user-management">{t('User management')}</Link>,
      key: 'user-management',
    },
    {
      label: (
        <Link to="/terminology-management">{t('Terminology management')}</Link>
      ),
      key: 'terminology-management',
    },
    {
      label: <Typography.Text>{t('Update system email')}</Typography.Text>,
      key: 'update-system-email',
      onClick: () => toggleEmailUpdateModal(true),
    },
    {
      label: <Typography.Text>{t('Personal information')}</Typography.Text>,
      key: 'personla-information',
      onClick: () => toggleUserUpdateModal(true),
    },
    {
      label: <Typography.Text>{t('Guideline content')}</Typography.Text>,
      key: 'update-guide-content',
      onClick: () => toggleGuideUpdateModal(true),
    },
    {
      label: t('Log out'),
      key: 'logout',
      onClick: handleLogout,
      style: { color: 'red' },
    },
  ];

  const adminItems: MenuProps['items'] = [
    {
      label: <Link to="/user-management">{t('User management')}</Link>,
      key: 'user-management',
    },
    {
      label: (
        <Link to="/terminology-management">{t('Terminology management')}</Link>
      ),
      key: 'terminology-management',
    },
    {
      label: <Typography.Text>{t('Personal information')}</Typography.Text>,
      key: 'personal-information',
      onClick: () => toggleUserUpdateModal(true),
    },
    {
      label: <Typography.Text>{t('Guideline content')}</Typography.Text>,
      key: 'update-guide-content',
      onClick: () => toggleGuideUpdateModal(true),
    },
    {
      label: t('Log out'),
      key: 'logout',
      onClick: handleLogout,
      style: { color: 'red' },
    },
  ];

  const getItemsByRole = (userInfo: LoginResDTO) => {
    if (userInfo.is_superuser) return superAdminItems;

    if (userInfo.user_role === 'admin') return adminItems;

    return userItems;
  };

  return (
    <RightSideHeaderWrapper>
      <Select
        defaultValue={lang}
        style={{ width: '20px' }}
        popupMatchSelectWidth={false}
        suffixIcon={null}
        optionLabelProp="show"
        onChange={onChange}
        options={[
          {
            value: 'vn',
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={VnFlag}
                  alt=""
                  width={20}
                  style={{ marginRight: '10px' }}
                />{' '}
                Việt Nam
              </div>
            ),
            show: <img src={VnFlag} alt="" width={20} />,
          },
          {
            value: 'en',
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EnFlag}
                  alt=""
                  width={20}
                  style={{ marginRight: '10px' }}
                />{' '}
                English
              </div>
            ),
            show: <img src={EnFlag} alt="" width={20} />,
          },
        ]}
      />
      {user && (
        <Dropdown menu={{ items: getItemsByRole(user) }} trigger={['click']}>
          <img
            src={AvatarDefault}
            alt=""
            width={32}
            style={{ marginLeft: '16px', cursor: 'pointer' }}
          />
        </Dropdown>
      )}
      <UserUpdateModal
        open={isUserUpdateModalOpen}
        toggleOpen={toggleUserUpdateModal}
      />
      <EmailUpdateModal
        open={isEmailUpdateModalOpen}
        toggleOpen={toggleEmailUpdateModal}
      />
      <GuideModal
        isEdit={user?.user_role !== 'user'}
        open={isGuideUpdateModalOpen}
        toggleOpen={toggleGuideUpdateModal}
      />
    </RightSideHeaderWrapper>
  );
}
