/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Trans, useTranslation } from 'react-i18next';
import { notification } from 'antd';

import CopyIcon from 'assets/icons/copy-icon.png';

import { CustomModal, SolidBtn } from 'components';
import type { IUser } from 'types';
import { ShowNewPassWrapper } from './style';

interface ShowNewPasswordModalProps {
  isModalOpen: boolean;
  width?: number;
  selectedUser: IUser;
  newPassword: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

export function ShowNewPasswordModal({
  isModalOpen,
  width = 600,
  selectedUser,
  newPassword,
  setIsModalOpen,
}: ShowNewPasswordModalProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(newPassword);
      notification.success({
        message: 'Copied',
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CustomModal
      centered
      footer={null}
      title={t('Reset password')}
      open={isModalOpen}
      width={width}
      onCancel={handleClose}
    >
      <ShowNewPassWrapper>
        <p className="sub-title">
          <Trans
            i18nKey="The {{name}} password has been reset as below:"
            values={{
              name: selectedUser.email,
            }}
          >
            The <strong>{selectedUser.email}</strong> password has been reset as
            below:
          </Trans>
        </p>
        <div className="show-pass">
          <p className="pass">{newPassword}</p>
          <p className="copy-btn" onClick={copyToClipboard}>
            <img src={CopyIcon} alt="" />
          </p>
        </div>
        <div
          className="footer"
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <SolidBtn
              type="primary"
              htmlType="submit"
              style={{ padding: '8px 20px' }}
              onClick={handleClose}
            >
              OK
            </SolidBtn>
          </div>
        </div>
      </ShowNewPassWrapper>
    </CustomModal>
  );
}
