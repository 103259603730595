import type { TableColumnsType } from 'antd';
import type { LanguageType } from 'types';
import type { TFunction } from 'i18next';
import type {
  DuplicatedLines,
  DuplicatedProcesses,
  DuplicatedProducts,
  DuplicatedXRs,
} from './types';

export const duplicatedLineColumns = (
  lang: LanguageType,
  t: TFunction<'translation', undefined>,
): TableColumnsType<DuplicatedLines> => [
  {
    title: t('Group'),
    dataIndex: lang === 'vn' ? 'group_name' : 'group_name_en',
  },
  {
    title: t('Line'),
    dataIndex: lang === 'vn' ? 'line_name' : 'line_name_en',
  },
];

export const duplicatedProcessColumns = (
  lang: LanguageType,
  t: TFunction<'translation', undefined>,
): TableColumnsType<DuplicatedProcesses> => [
  {
    title: t('Group'),
    dataIndex: lang === 'vn' ? 'group_name' : 'group_name_en',
  },
  {
    title: t('Process'),
    dataIndex:
      lang === 'vn' ? 'process_equipment_name' : 'process_equipment_name_en',
  },
];

export const duplicatedProductColumns = (
  lang: LanguageType,
  t: TFunction<'translation', undefined>,
): TableColumnsType<DuplicatedProducts> => [
  {
    title: t('Product ID'),
    dataIndex: 'product_id',
  },
  {
    title: t('Product line'),
    dataIndex: lang === 'vn' ? 'product_line_name' : 'product_line_name_en',
  },
];

export const duplicatedXRColumns = (
  lang: LanguageType,
  t: TFunction<'translation', undefined>,
): TableColumnsType<DuplicatedXRs> => [
  {
    title: t('Control chart name'),
    dataIndex: lang === 'vn' ? 'xr_name' : 'xr_name_en',
  },
  {
    title: t('Line'),
    dataIndex: lang === 'vn' ? 'line_name' : 'line_name_en',
  },
  {
    title: t('Process'),
    dataIndex:
      lang === 'vn' ? 'process_equipment_name' : 'process_equipment_name_en',
  },
];
