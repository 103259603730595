import { CustomModal, SolidBtn } from 'components';
import { useTranslation } from 'react-i18next';

interface ContactUsProps {
  open: boolean;
  toggleOpen: (val: boolean) => void;
}

export function ContactUs({ open, toggleOpen }: ContactUsProps) {
  const { t } = useTranslation();

  return (
    <CustomModal
      open={open}
      width={600}
      onCancel={() => toggleOpen(false)}
      onClose={() => toggleOpen(false)}
      title={t('Contact us')}
      footer={
        <SolidBtn
          onClick={() => toggleOpen(false)}
          type="primary"
          style={{ padding: '8px 20px' }}
        >
          {t('Close')}
        </SolidBtn>
      }
    >
      <ul>
        <li>
          <strong>{t('Company name')}: </strong>
          {t('DENSO MANUFACTURING VIETNAM CO., LTD')}
        </li>
        <li>
          <strong>{t('Address')}: </strong>
          {t(
            'Plot E-1, Thang Long Industrial Park, Kim Chung Commune, Dong Anh District, Hanoi, Vietnam',
          )}
        </li>
        <li>
          <strong>{t('Phone number')}: </strong>+84(24) 3881 1608
        </li>
        <li>
          <strong>{t('Working hours')}: </strong>
          {t('Monday to Friday, 8:10 AM ~ 5:15 PM')}
        </li>
        <li>
          <strong>{t('Contact Information')}:</strong>
          <br />- <strong>{t('Name')}: </strong>
          Dao Minh Tuan (Mr.)
          <br />- <strong>E-mail: </strong>dao.minh.tuan.a7s@ap.denso.com
          <br />- <strong>{t('Department')}: </strong>
          Production Engineering 2 <br />- <strong>{t('Section')}: </strong>
          Planning
        </li>
      </ul>
    </CustomModal>
  );
}
