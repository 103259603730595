import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { FormProps } from 'antd';
import {
  Button,
  Checkbox,
  Col,
  Form,
  notification,
  Row,
  Spin,
  Tooltip,
} from 'antd';

import { useState } from 'react';
import ArrowRight from 'assets/icons/arrow-right.png';
import BlueArrow from 'assets/icons/blue-arrow.png';
import { AuthenInput, AuthenLink } from 'layouts/AuthenLayout/style';
import { register } from 'services/auth.service';
import type { ErrorType } from 'hooks/useErrors';
import { useErrors } from 'hooks/useErrors';
import { SolidBtn } from 'components/Buttons';
import { passwordRegex } from 'constants/regex';
import { useLangStore } from 'stores';
import { SignUpWrapper } from '../style';
import { TermConditionModal } from './TermConditionModal';

type FieldType = {
  firstName?: string;
  lastName?: string;
  password?: string;
  confirmPassword?: string;
  email?: string;
  employee_id?: string;
  isAgreed?: boolean;
};

function SignupForm({
  setIsSignupForm,
}: {
  setIsSignupForm: (isSignupForm: boolean) => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { lang } = useLangStore();
  const { mapToFormErrors } = useErrors();
  const [isLoading, setIsLoading] = useState(false);
  const [termModalOpen, toggleTermModal] = useState(false);

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      if (!values) return;
      setIsLoading(true);

      await register({
        email: values.email?.trim() || '',
        first_name: values.firstName?.trim(),
        last_name: values.lastName?.trim(),
        employee_id: values.employee_id?.trim() || '',
        password: values.password?.trim() || '',
        language: lang || 'en',
      });
      setIsLoading(false);

      notification.success({
        message: t('Register successfully'),
      });
      setIsSignupForm(false);
    } catch (error) {
      setIsLoading(false);

      form.setFields(mapToFormErrors(error as ErrorType));
      notification.error({
        message: t('Register unsuccessfully'),
      });
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo,
  ) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <SignUpWrapper>
      <Spin spinning={isLoading}>
        <p className="title">
          {t('Sign up')} <img src={BlueArrow} alt="" width={32} />
        </p>
        <p className="sub-title">
          {t('Create account with your email and Denso employee ID')}
        </p>
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item<FieldType> name="firstName">
                <AuthenInput
                  placeholder={`${t('Enter first name')}`}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<FieldType> name="lastName">
                <AuthenInput
                  placeholder={`${t('Enter last name')}`}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item<FieldType>
                name="email"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t('Please input email field!'),
                  },
                  { type: 'email', message: t('Invalid email field') },
                ]}
              >
                <AuthenInput placeholder={`${t('Enter email')} *`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<FieldType>
                name="employee_id"
                rules={[
                  {
                    required: true,
                    message: t('Please input employee ID field!'),
                  },
                ]}
              >
                <AuthenInput
                  placeholder={`${t('Enter employee ID')} *`}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item<FieldType>
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('Please input password field!'),
                  },
                  {
                    pattern: passwordRegex,
                    message: t('Invalid password field'),
                  },
                ]}
              >
                <AuthenInput.Password
                  className="custom-password-input"
                  placeholder={`${t('Enter password')} *`}
                  addonAfter={
                    <Tooltip
                      color="#fff"
                      key="#fff"
                      overlayInnerStyle={{
                        backgroundColor: '#fff',
                        color: '#262626',
                        borderRadius: '6px',
                      }}
                      placement="right"
                      title={
                        <div style={{ padding: '16px' }}>
                          <ul
                            style={{
                              listStyleType: 'disc',
                              paddingLeft: '20px',
                            }}
                          >
                            <li>
                              {t(
                                'Password must be at least 8 characters long.',
                              )}
                            </li>
                            <li>
                              {t(
                                'It must include at least one uppercase letter, one lowercase letter, and one number.',
                              )}
                            </li>
                          </ul>
                        </div>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<FieldType>
                name="confirmPassword"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('Please retype your password!'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value)
                        return Promise.resolve();

                      return Promise.reject(
                        new Error(
                          t('The new password that you entered do not match!'),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <AuthenInput.Password
                  placeholder={`${t('Retype password')} *`}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item<FieldType>
            name="isAgreed"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('Should accept agreement'))),
              },
            ]}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Checkbox>
              {t('I agree to')}{' '}
              <Button
                style={{ margin: 0, padding: 0 }}
                type="link"
                onClick={() => toggleTermModal(true)}
              >
                {t('Denso GPT Terms & Private Policy')}
              </Button>
            </Checkbox>
          </Form.Item>
          <div className="button-area">
            <AuthenLink to="/login">{t('Back to Login')}</AuthenLink>
            <SolidBtn type="primary" htmlType="submit">
              {t('Create account')} <img src={ArrowRight} alt="" width={20} />
            </SolidBtn>
          </div>
        </Form>
      </Spin>
      <TermConditionModal open={termModalOpen} toggleOpen={toggleTermModal} />
    </SignUpWrapper>
  );
}

export default SignupForm;
