import type { FormProps, SelectProps } from 'antd';
import { Form, Input, message, Select, Spin, Typography } from 'antd';
import { CustomModal } from 'components';
import { useErrors, type ErrorType } from 'hooks/useErrors';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createTerminologyByType,
  getLineGroups,
  getTerminologyByType,
  updateTerminologyByType,
} from 'services/terminologyManagement.service';
import type { CreateLineReqDTO, ILineGroup } from 'types';
import { getStringFromReactNode } from 'utils';

interface AddEditLineModalProps {
  open: boolean;
  toggleOpen: (value: boolean) => void;
  refetch: () => Promise<void>;
  lineId?: number;
  resetSingleState?: () => void;
}

export function AddEditLineModal(props: AddEditLineModalProps) {
  const { open, toggleOpen, refetch, lineId, resetSingleState } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateLineReqDTO>();
  const [lineGroups, setLineGroups] = useState<ILineGroup[]>([]);
  const { mapToFormErrors } = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const isEdit = !!lineId;

  useEffect(() => {
    if (!lineId || isLoading || !isEdit || !open) return;
    setIsLoading(true);
    getTerminologyByType<'Line'>('Line', lineId)
      .then((res) => {
        setIsLoading(false);

        form.setFieldsValue(res);

        return res;
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [open, lineId]);

  const handleOk = () => {
    form.submit();
  };

  const handleFinish: FormProps['onFinish'] = async (
    values: CreateLineReqDTO,
  ) => {
    try {
      setIsLoading(true);

      if (isEdit && lineId)
        await updateTerminologyByType('Line', lineId, values);
      else await createTerminologyByType('Line', values);

      message.success({
        content: t(
          isEdit
            ? 'Terminology updated successfully'
            : 'Terminology created successfully',
        ),
      });

      setIsLoading(false);
      await refetch();
      toggleOpen(false);
    } catch (error) {
      setIsLoading(false);
      form.setFields(mapToFormErrors(error as ErrorType));
      message.error({
        content: t(
          isEdit
            ? 'Terminology updated unsuccessfully'
            : 'Terminology created unsuccessfully',
        ),
      });
    }
  };

  const handleClose = () => {
    resetSingleState?.();
    toggleOpen(false);
  };

  const getLineGroupData = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await getLineGroups();

      setLineGroups(res);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) getLineGroupData();
  }, [getLineGroupData, open]);

  return (
    <CustomModal
      {...props}
      title={isEdit ? t('Edit terminology') : t('New terminology')}
      onOk={handleOk}
      onCancel={handleClose}
      onClose={handleClose}
    >
      <Spin spinning={isLoading}>
        <Form<CreateLineReqDTO>
          preserve={false}
          form={form}
          layout="vertical"
          validateTrigger={['onChange']}
          onFinish={handleFinish}
        >
          <Form.Item<CreateLineReqDTO>
            label={t('Line terminology ENG')}
            required
            name="line_name_en"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input placeholder={t('Enter terminology')} />
          </Form.Item>
          <Form.Item<CreateLineReqDTO>
            label={t('Line terminology VIE')}
            required
            name="line_name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input placeholder={t('Enter terminology')} />
          </Form.Item>
          <Form.Item<CreateLineReqDTO>
            label={t('Line group')}
            required
            name="line_group_id"
            rules={[
              {
                required: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Select
              placeholder={t('Choose group')}
              options={
                lineGroups.map((item) => ({
                  label: (
                    <Typography.Text style={{ textWrap: 'wrap' }}>
                      {item.group_name} / {item.group_name_en}
                    </Typography.Text>
                  ),
                  value: item.id,
                })) as SelectProps['options']
              }
              showSearch
              allowClear
              filterOption={(input, option) =>
                getStringFromReactNode(option?.label)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form>
      </Spin>
    </CustomModal>
  );
}
