import { useEffect, useState } from 'react';
import { Flex, Layout, Spin, Typography } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import ChatbotAvatar from 'assets/icons/chatbot-avatar.png';
import ChatLayout from 'layouts/ChatLayout';
import { RightSideHeader } from 'components';
import { getConversations } from 'services/conversation.service';

import { getMessages } from 'services/chat.service';
import { useChatStore } from 'stores/useChatStore';
import type { IConversation } from 'types';
import ChatBar from './components/ChatBar';
import { ChatHistory } from './components/ChatHistory';
import MenuSideBar from './components/MenuSideBar';
import { mapMessageData } from './utils';

function Chat() {
  const {
    curConversation,
    setConversations,
    setMessages,
    messages,
    setCurConversation,
  } = useChatStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleConversationClick = (item: IConversation) => {
    setCurConversation(item);
  };

  const scrollToBottom = () => {
    const ele = document.getElementById('scrollableDiv');

    if (!ele) return;

    ele.scrollTop = ele.scrollHeight;
  };

  const getData = async () => {
    try {
      setIsLoading(true);

      const result = await getConversations();
      const sortedByCreated = result.sort((a, b) =>
        a.created < b.created ? 1 : -1,
      );

      setConversations(sortedByCreated);
      if (sortedByCreated.length === 0) {
        setCurConversation(null);
        setMessages([]);
      }

      setIsLoading(false);

      return sortedByCreated;
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      return [];
    }
  };

  const getMessageData = async (conversationId: number) => {
    try {
      setIsLoading(true);

      const messagesResponse = await getMessages(conversationId);

      const mappedMsgs = mapMessageData(messagesResponse.results);

      const sortedByCreated = mappedMsgs.sort((a, b) =>
        (a.created ?? 0) > (b.created ?? 0) ? 1 : -1,
      );

      setMessages(sortedByCreated);

      setIsLoading(false);

      scrollToBottom();
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!messages || messages.length === 0) return;
    messages.forEach((msg) => {
      console.info({
        'Câu hỏi gốc': msg.question,
        'Câu hỏi đã sửa': msg.correctQuestion,
        'Text SQL': msg.textSql,
        'Chart SQL':
          msg.metaData[msg.selectTimeLevelOption as never]?.chart_sql,
      });
    });
  }, [messages]);

  useEffect(() => {
    if (curConversation) getMessageData(curConversation.id);
  }, [curConversation]);

  useEffect(() => {
    getData()
      .then((res) => {
        if (res.length > 0) setCurConversation(res[0]);

        return res;
      })
      .catch(() => {});
  }, []);

  return (
    <ChatLayout>
      <Spin spinning={isLoading} fullscreen />
      <MenuSideBar
        onConversationClick={handleConversationClick}
        refetch={getData}
      />
      <Layout>
        <Header
          style={{
            backgroundColor: 'white',
            padding: 0,
            borderBottom: '1px solid #2626261A',
          }}
        >
          <Flex
            justify={curConversation ? 'space-between' : 'flex-end'}
            align="center"
            style={{ height: '100%', width: '100%' }}
          >
            {curConversation && (
              <Flex style={{ width: '80%' }} align="center" gap={16}>
                <img src={ChatbotAvatar} alt="chatbot-avatar" />
                <Typography.Text ellipsis style={{ width: '100%' }}>
                  {curConversation.conversation_name}
                </Typography.Text>
              </Flex>
            )}
            <RightSideHeader />
          </Flex>
        </Header>
        <Content
          style={{
            backgroundColor: 'white',
          }}
        >
          <Flex justify="center" style={{ height: '100%', paddingTop: 10 }}>
            <Flex
              gap={10}
              vertical
              style={{ height: '100%', width: '100%' }}
              justify="space-between"
              align="center"
            >
              <ChatHistory />
              <ChatBar refetch={getData} />
            </Flex>
          </Flex>
        </Content>
      </Layout>
    </ChatLayout>
  );
}

export default Chat;
