import { Button } from 'antd';
import styled from 'styled-components';

export const TextButton = styled(Button)`
  border: none;
  outline: none;
  box-shadow: none;
  color: #f5222d;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
`;
