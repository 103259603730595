export const mentionStyle = {
  control: {},
  '&multiLine': {
    control: {
      minHeight: 20,
      maxHeight: 45,
      padding: 0,
      fontSize: 16,
    },
    highlighter: {
      outline: 0,
      border: 0,
      padding: 0,
      overflow: 'hidden',
      minHeight: 20,
      maxHeight: 45,
    },
    input: {
      outline: 0,
      border: 0,
      overflow: 'auto',
      padding: 0,
      minHeight: 20,
      maxHeight: 45,
    },
    width: '90%',
    margin: '16px 0',
    backgroundColor: '#F5F5F5',
    caretColor: '#21b5ee',
  },

  suggestions: {
    list: {
      gap: 4,
      display: 'flex',
      flexDirection: 'column',
      margin: 8,
    },
    item: {
      padding: '8px 10px',
      borderRadius: 6,
      '&focused': {
        backgroundColor: '#F0F0F0',
      },
    },
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 12,
    width: 244,
    boxShadow: '0px 12px 32px 0px #010C1B1A',
  },
};

export const mentionItemStyle = {
  backgroundColor: '#cee4e5',
};
