import type { SaveGuidelineReqDTO, IGuideline, IParam } from 'types';
import { axiosInstance } from '../utils/axios';

export const saveDraftGuideline = (params: SaveGuidelineReqDTO) =>
  axiosInstance.post<never, IGuideline>('/api/guideline/draft', params);

export const savePublishedGuideline = (params: SaveGuidelineReqDTO) =>
  axiosInstance.post<never, IGuideline>('/api/guideline/draft/publish', params);

export const getPublishedGuidelines = (params?: IParam) =>
  axiosInstance.get<never, IGuideline[]>('/api/guideline', {
    params,
  });

export const getDraftGuidelines = (params?: IParam) =>
  axiosInstance.get<never, IGuideline[]>('/api/guideline/draft', {
    params,
  });

export const cancelDraftGuideline = () =>
  axiosInstance.delete<never, IGuideline>('/api/guideline/draft/cancel');
