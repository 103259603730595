import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Check from 'assets/icons/check-circle-fill.png';
import { SolidBtn } from 'components/Buttons';
import { useState } from 'react';
import { Button } from 'antd';
import { SignUpSuccessfullyWrapper } from '../style';
import { ContactUs } from './ContactUsModal';

function SignUpSuccessfully() {
  const { t } = useTranslation();
  const [contactUsModalOpen, toggleContactUsModal] = useState(false);

  return (
    <SignUpSuccessfullyWrapper>
      <p className="img-area">
        <img src={Check} alt="Check" width={68} />
      </p>
      <p className="title">{t('Sign up request successfully!')}</p>
      <p className="sub-title">
        {t(
          'After we will send confirmation to your email address within 24h, following confirmation you can log into the system',
        )}
      </p>
      <p className="contact">
        {t('If you need a support, please')}{' '}
        <Button
          type="link"
          style={{ margin: 0, padding: 0 }}
          onClick={() => toggleContactUsModal(true)}
        >
          {t('Contact us')}
        </Button>
      </p>
      <Link to="/login">
        <SolidBtn
          type="primary"
          htmlType="submit"
          style={{ padding: '12px 24px' }}
        >
          {t('Back to Login')}
        </SolidBtn>
      </Link>
      <ContactUs open={contactUsModalOpen} toggleOpen={toggleContactUsModal} />
    </SignUpSuccessfullyWrapper>
  );
}

export default SignUpSuccessfully;
