import { Form, Input, message, Spin } from 'antd';
import type { FormProps, ModalProps } from 'antd';

import { CustomModal } from 'components';
import type { ErrorType } from 'hooks/useErrors';
import { useErrors } from 'hooks/useErrors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createProductLine } from 'services/terminologyManagement.service';
import type { CreateProductLineReqDTO } from 'types';

interface AddProductGroupModalProps {
  refetch: () => Promise<void>;
  toggleOpen: (value: boolean) => void;
  open: boolean;
}

export function AddProductGroupModal(props: AddProductGroupModalProps) {
  const { toggleOpen, refetch, open } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const [form] = Form.useForm<CreateProductLineReqDTO>();
  const { mapToFormErrors } = useErrors();

  const handleOk: ModalProps['onOk'] = () => {
    form.submit();
  };

  const handleFinish: FormProps['onFinish'] = async (
    values: CreateProductLineReqDTO,
  ) => {
    try {
      setIsLoading(true);
      await createProductLine(values);

      message.success({ content: t('Group created successfully') });
      setIsLoading(false);
      await refetch();
      toggleOpen(false);
    } catch (error) {
      setIsLoading(false);
      form.setFields(mapToFormErrors(error as ErrorType));
      message.error({ content: t('Group created unsuccessfully') });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        open={open}
        onCancel={() => toggleOpen(false)}
        onClose={() => toggleOpen(false)}
        title={t('Add new product line')}
        onOk={handleOk}
        centered
      >
        <Form
          preserve={false}
          form={form}
          layout="vertical"
          validateTrigger={['onChange']}
          onFinish={handleFinish}
        >
          <Form.Item<CreateProductLineReqDTO>
            label={t('Product line ENG')}
            required
            name="product_line_name_en"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input placeholder={t('Enter product line')} />
          </Form.Item>
          <Form.Item<CreateProductLineReqDTO>
            label={t('Product line VIE')}
            required
            name="product_line_name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input placeholder={t('Enter product line')} />
          </Form.Item>
        </Form>
      </CustomModal>
    </Spin>
  );
}
