// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${day}-${month} ${hours}:${minutes}:${seconds}`;
}

export function parseStringToDate(dateString: string) {
  const validDatMonthYearFormat = dayjs(dateString, 'MMMM Do , YYYY');

  if (validDatMonthYearFormat.isValid())
    return validDatMonthYearFormat.format('DD/MM/YYYY');

  const validMonthYearFormat = dayjs(dateString, 'MMMM YYYY');

  if (validMonthYearFormat.isValid())
    return validMonthYearFormat.format('MM/YYYY');

  const validDayMonthFormat = dayjs(dateString, 'MMMM Do');

  if (validDayMonthFormat.isValid()) return validDayMonthFormat.format('DD/MM');

  return dateString;
}

export function parseMonth(monthString: string) {
  const validMonth = dayjs(monthString, 'MMMM');

  if (validMonth.isValid()) return validMonth.format('MM');

  return null;
}
