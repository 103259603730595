import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary';
import { PrivateRoute } from './components/PrivateRoute';
import Chat from './pages/Chat';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import { NotFoundPage } from './pages/NotFoundPage';
import SignUp from './pages/Signup';
import UserManagementTabs from './pages/UserManagement';
import TerminologyManagementTabs from './pages/TerminologyManagement';
import { useAuthStore, useLangStore } from './stores';
import { UserRole } from './types/models/user';

function App() {
  const { i18n } = useTranslation();
  const { lang } = useLangStore();
  const { user } = useAuthStore();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <BrowserRouter>
      <ErrorBoundary fallbackRender={NotFoundPage}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/chat" replace />} // Use "replace" to replace the current history entry
          />
          <Route
            path="chat"
            element={<PrivateRoute roles={[UserRole.User, UserRole.Admin]} />}
          >
            <Route index element={<Chat />} />
          </Route>
          <Route
            path="user-management"
            element={<PrivateRoute roles={[UserRole.Admin]} />}
          >
            <Route index element={<UserManagementTabs />} />
          </Route>
          <Route
            path="terminology-management"
            element={<PrivateRoute roles={[UserRole.Admin]} />}
          >
            <Route index element={<TerminologyManagementTabs />} />
          </Route>
          <Route
            path="login"
            element={user ? <Navigate to="/" replace /> : <Login />}
          />
          <Route
            path="sign-up"
            element={user ? <Navigate to="/" replace /> : <SignUp />}
          />
          <Route
            path="forgot-password"
            element={user ? <Navigate to="/" replace /> : <ForgotPassword />}
          />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
