import type { IConversation } from 'types';
import type { IMessage } from 'types/models';
import { create } from 'zustand';

interface IChatState {
  isSending: boolean;
  toggleIsSending: (value: boolean) => void;

  chartError: string;
  setChartError: (value: string) => void;

  messages: IMessage[];
  setMessages: (data: IMessage[]) => void;
  updateLastMessage: (data: IMessage) => void;
  updateMessageById: (data: IMessage) => void;
  insertMessage: (data: IMessage) => void;

  conversations: IConversation[];
  setConversations: (data: IConversation[]) => void;

  curConversation: IConversation | null;
  setCurConversation: (data: IConversation | null) => void;

  reset: () => void;
}

export const useChatStore = create<IChatState>((set) => ({
  isSending: false,
  toggleIsSending: (value) =>
    set((state) => ({ ...state, isSending: value ?? !state.isSending })),

  messages: [],
  insertMessage: (value) =>
    set((state) => ({
      ...state,
      messages: [...state.messages, value],
    })),
  setMessages: (value) =>
    set((state) => ({
      ...state,
      messages: value,
    })),
  updateLastMessage: (value) =>
    set((state) => {
      const newMessages = state.messages;

      newMessages[newMessages.length - 1] = value;

      return {
        ...state,
        messages: newMessages,
      };
    }),
  updateMessageById: (value) =>
    set((state) => {
      const newMessages = state.messages;

      const msgIdx = state.messages.findIndex((msg) => msg.id === value.id);

      if (msgIdx < 0) return state;

      newMessages[msgIdx] = value;

      return {
        ...state,
        messages: newMessages,
      };
    }),

  conversations: [],
  setConversations: (value) =>
    set((state) => ({
      ...state,
      conversations: value,
    })),

  curConversation: null,
  setCurConversation: (value) =>
    set((state) => ({
      ...state,
      curConversation: value,
    })),

  chartError: '',
  setChartError: (value) =>
    set((state) => ({
      ...state,
      chartError: value,
    })),

  reset: () => set({ isSending: false, messages: [] }),
}));
