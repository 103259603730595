import { Button } from 'antd';
import styled from 'styled-components';

export const UserManagementTab = styled.div`
  .header {
    padding: 8px 0 16px;
    display: flex;
    .left-side {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .right-side {
      display: flex;
      align-items: center;
    }
  }
`;

export const TextButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #f5222d;
  border: none;
  box-shadow: none;
  padding: 0;
  height: auto;
  background: transparent !important;
  outline: none !important;
`;

export const ShowNewPassWrapper = styled.div`
  .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #595959;
    padding-bottom: 12px;
    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      color: #636363;
    }
  }
  .show-pass {
    padding: 16px 0;
    display: flex;
    align-items: center;
    .pass {
      border: 1px solid #bfbfbf;
      padding: 14px 16px;
      border-radius: 8px;
      flex: 1;
    }
    .copy-btn {
      width: 36px;
      height: 36px;
      padding: 6px;
      border-radius: 8px;
      margin-left: 12px;
      background: #f5f5f5;
      cursor: pointer;
    }
  }
`;
