import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { notification, Spin } from 'antd';
import { ConfirmModal } from 'components';
import { resetPassword } from 'services/userManagement.service';
import type { IUser } from 'types';
import { ShowNewPasswordModal } from './ShowNewPasswordModal';

interface ResetPasswordModalProps {
  user: IUser;
  open: boolean;
  setOpen: (val: boolean) => void;
}

export function ResetPasswordModal({
  user,
  open,
  setOpen,
}: ResetPasswordModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [isOpenShowNewPassModal, setIsOpenShowNewPassModal] = useState(false);

  const handleResetPassword = async () => {
    try {
      if (!user) return;
      setIsLoading(true);

      const res = await resetPassword(user?.id);

      setNewPassword(res.reset_password);
      setOpen(false);
      setIsOpenShowNewPassModal(true);
      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: t('Create password unsuccessfully'),
      });
      setIsLoading(false);
    }
  };

  if (isLoading) return <Spin spinning={isLoading} fullscreen />;

  return (
    <>
      <ConfirmModal
        content={
          <Trans
            i18nKey="Are you sure to reset password of user"
            values={{
              name: user.email,
            }}
          >
            Are you sure to reset password of user
            <strong>{user.email}</strong>?
          </Trans>
        }
        handleOk={handleResetPassword}
        setIsModalOpen={setOpen}
        title={t('Reset password')}
        okBtn="Reset"
        isModalOpen={open}
      />

      {user && (
        <ShowNewPasswordModal
          newPassword={newPassword}
          selectedUser={user}
          setIsModalOpen={setIsOpenShowNewPassModal}
          isModalOpen={isOpenShowNewPassModal}
        />
      )}
    </>
  );
}
