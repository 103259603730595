import type { IGuideline } from 'types';

import { convert } from 'html-to-text';

export const mergeGuidelines = (
  targetGuidelinesEn: IGuideline[],
  targetGuidelinesVi: IGuideline[],
) => {
  const mappedGuidelinesEn: IGuideline[] = targetGuidelinesEn.map(
    (item, index) => ({
      ...item,
      block_number: index + 1,
    }),
  );

  const mappedGuidelinesVi: IGuideline[] = targetGuidelinesVi.map(
    (item, index) => ({
      ...item,
      block_number: index + 1,
    }),
  );

  return [...mappedGuidelinesEn, ...mappedGuidelinesVi];
};

export const validateGuidelines = (guidelines: IGuideline[]): boolean => {
  let isValid = true;

  guidelines.forEach((item) => {
    const text = convert(item.content);

    if (text.length === 0 || text.length > 150) isValid = false;
  });

  return isValid;
};
