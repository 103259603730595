import { Button } from 'antd';
import styled from 'styled-components';

export const SolidBtn = styled(Button)`
  background: #f5222d;
  font-size: 16px;
  font-weight: 500;
  padding: 13px 65px;
  border-radius: 8px;
  height: auto;
  &:hover:not(:disabled) {
    background: #f5222d !important;
  }
`;
