import type { TableProps } from 'antd';
import { Typography } from 'antd';
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { useLangStore } from 'stores';
import { MeasurementMap } from 'pages/Chat/constants';
import type { ITableData, LanguageType } from 'types';
import {
  translateCountColumnTitle,
  translateMonthColumnTitle,
} from 'pages/Chat/utils';
import { parseStringToDate } from 'utils/time';
import { CustomTable } from './style';
import './style.scss';

export interface TableDataProps
  extends Omit<TableProps, 'columns' | 'dataSource'> {
  data: ITableData;
  language?: LanguageType;
}

export function TableData(props: TableDataProps) {
  const { data, language, ...rest } = props;
  const { t } = useTranslation();
  const { lang } = useLangStore();
  const [pageSize, setPageSize] = useState(20);

  const columns = data.header;
  const { mergedCols } = data;
  const dataSource = data.body;

  const usedColumns: string[] = [];
  const groupElements = mergedCols?.map((mergedCol) => (
    <ColumnGroup key={mergedCol.title} title={mergedCol.title}>
      {mergedCol.keys.map((key) => {
        const targetCol = columns.find((item) => key === item.key);

        // eslint-disable-next-line react/jsx-no-useless-fragment
        if (!targetCol) return <></>;

        usedColumns.push(key);

        return (
          <Column
            title={targetCol?.colName}
            dataIndex={targetCol?.key}
            key={targetCol?.key}
            align="center"
            render={(value) => (
              <Typography.Text style={{ fontSize: 14, fontWeight: 500 }}>
                {value}
              </Typography.Text>
            )}
          />
        );
      })}
    </ColumnGroup>
  ));

  const columnElements = columns
    .filter((col) => !usedColumns.includes(col.key))
    .map((item) => {
      const lng = language ?? lang;

      const column1 = translateCountColumnTitle(item.colName, t, lng);
      const column2 = translateMonthColumnTitle(column1, t, lng);
      const column3 = parseStringToDate(column2);

      return (
        <Column
          align="left"
          title={column3}
          dataIndex={item.key}
          key={item.key}
          sorter={(a, b) => {
            const index = item.key as never as number;

            const aValue =
              typeof a[index] === 'string'
                ? a[index].replace(/\s+/g, '')
                : a[index];
            const bValue =
              typeof b[index] === 'string'
                ? b[index].replace(/\s+/g, '')
                : b[index];

            if (typeof aValue === 'number' && typeof bValue === 'number')
              return aValue - bValue;

            if (typeof aValue === 'string' && typeof bValue === 'string') {
              const dateA = new Date(aValue);
              const dateB = new Date(bValue);

              if (
                dateA.toString() !== 'Invalid Date' &&
                dateB.toString() !== 'Invalid Date'
              )
                return dateA.getTime() - dateB.getTime();
            }

            return aValue.localeCompare(bValue, undefined, {
              sensitivity: 'base',
            });
          }}
          render={(value) => {
            const unit = MeasurementMap[item.colName];

            return (
              <div style={{ minWidth: 120, maxWidth: 500 }}>
                <Typography.Text style={{ fontSize: 14, fontWeight: 500 }}>
                  {value}
                  {unit === '%' && unit}
                </Typography.Text>
              </div>
            );
          }}
        />
      );
    });

  return (
    <CustomTable
      scroll={{ y: 400, x: 'max-content' }}
      {...rest}
      dataSource={dataSource}
      bordered
      pagination={{
        position: ['bottomCenter'],
        size: 'small',
        style: { margin: '8px auto' },
        className: 'custom-pagination',
        pageSize,
        onShowSizeChange: (_, size) => setPageSize(size),
        total: dataSource.length,
        pageSizeOptions: [20, 50, 70, 100],
        showLessItems: true,
        showSizeChanger: dataSource.length > 20,
      }}
    >
      <Column
        align="center"
        title={t('No', { lng: language ?? lang })}
        key="No"
        width={80}
        render={(_v, _r, index: number) => (
          <div>
            <span className="pl-2">{index + 1}</span>
          </div>
        )}
      />
      {groupElements}
      {columnElements}
    </CustomTable>
  );
}
