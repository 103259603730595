import styled from 'styled-components';

export const RightSideHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    outline: none;
    box-shadow: none !important;
    background-color: transparent;
    padding: 0;
  }
  .ant-select-selection-item {
    display: flex;
  }
  .user-avatar {
    position: relative;
    z-index: 10;
    cursor: pointer;
    .menu {
      width: 150px;
      position: absolute;
      top: 100%;
      right: 0;
      border: 1px solid #ffccc7;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;
