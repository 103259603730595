export interface BaseChartProps {
  xCategories: string[][];
  data: number[];
  min?: number;
  max?: number;
}

export enum EChartType {
  Bar = 'Bar',
  Line = 'Line',
  Table = 'Table',
  Pie = 'Pie',
  Pareto = 'Pareto',
}

export type IChartType = keyof typeof EChartType;
