import { isValidElement, type ReactNode } from 'react';
import type { IMetadata } from 'types/models';

export const getFullnameByLang = (first_name: string, last_name: string) =>
  `${last_name} ${first_name}`;

export const capitalize = (str: string) => {
  if (!str) return str;

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const parseJSONToTableChart = (
  time: string,
  metadata?: string,
): [IMetadata, string[]] => {
  try {
    const parsedMetadata = JSON.parse(metadata ?? '{}') as IMetadata;

    const parsedTimeLevelOptions =
      typeof time === 'string' ? JSON.parse(time.replace(/'/g, '"')) : time;

    return [parsedMetadata, parsedTimeLevelOptions];
  } catch (err) {
    console.log(err);

    return [{}, []];
  }
};

export function getStringFromReactNode(node: ReactNode): string {
  if (typeof node === 'string') return node;

  if (Array.isArray(node)) return node.map(getStringFromReactNode).join('');

  if (isValidElement(node) && node.props.children)
    return getStringFromReactNode(node.props.children);

  return '';
}
