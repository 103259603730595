import type { IParam, IUser, Pagination } from 'types';
import { axiosInstance } from '../utils/axios';

export const getUserRequests = (params?: IParam) =>
  axiosInstance.get<never, Pagination<IUser>>('/api/user/approval', {
    params,
  });

export const approveUser = (ids: string[]) =>
  axiosInstance.put<never, IUser>('/api/user/approval', { ids });

export const rejectUser = (ids: string[]) =>
  axiosInstance.delete<never, IUser>('/api/user/approval', {
    data: { ids },
  });
