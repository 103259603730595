import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { FormProps } from 'antd';
import { Form, notification, Spin } from 'antd';

import { useState } from 'react';
import ArrowRight from 'assets/icons/arrow-right.png';
import BlueArrow from 'assets/icons/blue-arrow.png';
import AuthenLayout from 'layouts/AuthenLayout';
import { AuthenInput, AuthenLink } from 'layouts/AuthenLayout/style';
import { login } from 'services/auth.service';
import { useAuthStore } from 'stores';

import { SolidBtn } from 'components/Buttons';
import { LoginWrapper } from './style';

type FieldType = {
  username: string;
  password: string;
};
function Login() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useAuthStore();
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      setIsLoading(true);

      const res = await login(values.username, values.password);

      setUser(res);
      setIsLoading(false);

      navigate('/user-management');
      notification.success({
        message: t('Login successfully'),
      });
    } catch (error) {
      setIsLoading(false);

      notification.error({
        message: t('Invalid email/password'),
      });
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo,
  ) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthenLayout>
      <Spin spinning={isLoading}>
        <LoginWrapper>
          <p className="title">
            {t('Login')} <img src={BlueArrow} alt="" width={32} />
          </p>
          <p className="sub-title">
            {t('Everything in control with DensoGPT')}
          </p>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              name="username"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('Please input email field!'),
                },
              ]}
            >
              <AuthenInput placeholder={t('Enter email')} />
            </Form.Item>

            <Form.Item<FieldType>
              name="password"
              rules={[
                { required: true, message: t('Please input password field!') },
              ]}
            >
              <AuthenInput.Password placeholder={t('Enter password')} />
            </Form.Item>
            <div className="button-area">
              <SolidBtn type="primary" htmlType="submit">
                {t('Login')} <img src={ArrowRight} alt="" width={20} />
              </SolidBtn>
              <AuthenLink to="/forgot-password">
                {t('Forgot Password')}
              </AuthenLink>
            </div>
            <div className="footer">
              {t('Do not have an account?')}{' '}
              <AuthenLink to="/sign-up">{t('Create account')}</AuthenLink>
            </div>
          </Form>
        </LoginWrapper>
      </Spin>
    </AuthenLayout>
  );
}

export default Login;
