import type { IUser } from 'types';
import type { UpdatePasswordReqDTO } from 'types/dto/requests/user';
import { axiosInstance } from '../utils/axios';

export const updateUserProfile = (params?: {
  first_name?: string;
  last_name?: string;
}) => axiosInstance.put<never, IUser>('/api/userprofile/', params);

export const getUserProfile = () =>
  axiosInstance.get<never, IUser>('/api/userprofile');

export const updateUserPassword = (payload: UpdatePasswordReqDTO) =>
  axiosInstance.put<never, IUser>('/api/userprofile/changepassword/', payload);
