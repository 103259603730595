import { Navigate, Outlet } from 'react-router-dom';

import { useAuthStore } from 'stores';
import type { LoginResDTO, UserRole } from 'types';

export interface PrivateRouteProps {
  roles: UserRole[];
}
export function PrivateRoute({ roles }: PrivateRouteProps) {
  const { user } = useAuthStore();

  const isAccessable = (currentUser: LoginResDTO): boolean =>
    currentUser.is_superuser || roles.includes(currentUser?.user_role);

  // use with login
  return user && isAccessable(user) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
}
