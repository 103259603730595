import BarChart from './BarChart';
import LineChart from './LineChart';
import ParetoChart from './ParetoChart';
import PieChart from './PieChart';

type CompoundedComponent = {
  Bar: typeof BarChart;
  Line: typeof LineChart;
  Pie: typeof PieChart;
  Pareto: typeof ParetoChart;
};

const Charts: CompoundedComponent = {
  Bar: BarChart,
  Line: LineChart,
  Pie: PieChart,
  Pareto: ParetoChart,
};

Charts.Bar = BarChart;
Charts.Line = LineChart;
Charts.Pie = PieChart;
Charts.Pareto = ParetoChart;

export default Charts;
