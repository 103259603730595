import { CustomModal, SolidBtn } from 'components';
import { useTranslation } from 'react-i18next';

interface TermConditionModalProps {
  open: boolean;
  toggleOpen: (val: boolean) => void;
}

export function TermConditionModal({
  open,
  toggleOpen,
}: TermConditionModalProps) {
  const { t } = useTranslation();

  return (
    <CustomModal
      open={open}
      width={600}
      onCancel={() => toggleOpen(false)}
      onClose={() => toggleOpen(false)}
      title={t('Terms and Conditions')}
      footer={
        <SolidBtn
          onClick={() => toggleOpen(false)}
          type="primary"
          style={{ padding: '8px 20px' }}
        >
          {t('Close')}
        </SolidBtn>
      }
    >
      {t(
        'Welcome to the registration for using the Denso GPT software by the Production Engineering Department of Denso Manufacturing Vietnam (hereinafter referred to as the Production Engineering Department). Before continuing to use the software, you need to read and understand the following Terms and Conditions. Using this software means that you accept and comply with the following terms and conditions:',
      )}
      <br />
      <br />
      <strong>1. {t('Ownership')}:</strong>{' '}
      {t(
        'This software and all related documents are the property of the Production Engineering Department and are protected by intellectual property rights. You are not allowed to copy, distribute, rent, sublet, sell, transfer, or modify the software or any related documents without written permission from the Production Engineering Department.',
      )}
      <br />
      <br />
      <strong>2. {t('Usage Rights')}:</strong>{' '}
      {t(
        'By registering an account, you are granted the right to use this software for your personal or work-related purposes within the permitted scope. You are not allowed to use the software for any commercial or illegal purposes.',
      )}
      <br />
      <br />
      <strong>3. {t('Information Security')}:</strong>{' '}
      {t(
        'You commit to keeping confidential and secure all login information, account passwords, and any information provided directly or indirectly by the Denso GPT software. This information is considered confidential and proprietary to Denso Manufacturing Vietnam and is only allowed for internal use within the company. You are responsible for any activities that occur on your account. If you detect any suspicious activity or unauthorized access to your account, you must immediately notify DMVN PE.',
      )}
    </CustomModal>
  );
}
