import { ReactComponent as ChartBarIcon } from 'assets/icons/chart-bar.svg';
import { ReactComponent as ChartLineIcon } from 'assets/icons/chart-line.svg';
import { ReactComponent as ChartParetoIcon } from 'assets/icons/chart-pareto.svg';
import { ReactComponent as ChartPieIcon } from 'assets/icons/chart-pie.svg';
import { ReactComponent as TableDataIcon } from 'assets/icons/chart-tabledata.svg';
import type { IChartType } from 'types';
import { EChartType } from 'types';

export const chartOptions = [
  { value: EChartType.Table, icon: <TableDataIcon /> },
  { value: EChartType.Bar, icon: <ChartBarIcon /> },
  { value: EChartType.Pie, icon: <ChartPieIcon /> },
  { value: EChartType.Line, icon: <ChartLineIcon /> },
  { value: EChartType.Pareto, icon: <ChartParetoIcon /> },
];

export const defaultChartByColumns: Record<string, IChartType> = {
  planned_down_loss_percentage: EChartType.Bar,
  unplanned_down_loss: EChartType.Bar,
  unplanned_down_loss_percentage_by_date: EChartType.Bar,
  planned_down_loss_percentage_by_date: EChartType.Bar,

  total_scrap_amount: EChartType.Bar,
  error_type: EChartType.Bar,
  change_point_of: EChartType.Bar,
  total_of_change_point: EChartType.Bar,

  target_production_amount: EChartType.Bar,
  actual_production_amount: EChartType.Bar,

  product_amount_percentage: EChartType.Pie,

  oee_percentage: EChartType.Line,
  oee_percentage_by_date: EChartType.Line,
  oee: EChartType.Line,
  target_percentage: EChartType.Line,

  product_amount: EChartType.Bar,
  production_amount: EChartType.Bar,
  product_number: EChartType.Bar,
  part_number: EChartType.Bar,

  target_performance: EChartType.Line,
  target_line_performance: EChartType.Line,
  line_target_performance: EChartType.Line,
  actual_performance: EChartType.Line,
  actual_performance_amount: EChartType.Line,

  total_scrap_rate_target: EChartType.Line,
  total_scrap_rate: EChartType.Line,
  scrap_rate: EChartType.Line,

  xbar: EChartType.Line,
  xbar_avg: EChartType.Line,
  avg: EChartType.Line,
  cp: EChartType.Line,
  cpk: EChartType.Line,
  o: EChartType.Line,
  standard_deviation: EChartType.Line,
};

export const priorityDefaultChart: IChartType[] = [
  EChartType.Bar,
  EChartType.Line,
  EChartType.Pie,
  EChartType.Pareto,
];
