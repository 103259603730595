export const en = {
  errors: {
    exist: {
      email: 'A user with that email already exists',
      employee_id: 'A user with that employee id already exists',
      group_name: 'This line group is already in the system',
      group_name_en: 'This line group is already in the system',
      line_name: 'This terminology is already in the system',
      line_name_en: 'This terminology is already in the system',
      process_equipment_name: 'This terminology is already in the system',
      process_equipment_name_en: 'This terminology is already in the system',
      product_id: 'This ID is already in the system',
      product_line_name: 'This product line is already in the system.',
      product_line_name_en: 'This product line is already in the system.',
      xr_name: 'This terminology is already in the system',
      xr_name_en: 'This terminology is already in the system',
      conversation_name: 'A conversation with that name already exists.',
    },
    not_exist: {
      email: 'Email does not exists.',
    },
  },
  date: 'Date',
  item: 'Item',
  planned_performance_losses: 'Planned performance losses',
  business_date: 'Business date',
  start_of_week: 'Start of week',
  end_of_week: 'End of week',
  point_of_change_detail: 'Point of change details',
  time: 'Time',
  working_day: 'Day',
  total_of_change_point: 'Total of change point',
  process: 'Process',
  equipment: 'Equipment',
  XR: 'XR',
  'oee_percentage - target_percentage': 'oee_percentage - target_percentage',
  'oee_percentage_by_date - target_percentage':
    'Performance - Target Performance',
  'actual_production_amount - target_production_amount':
    'Actual production amount - Target production amount',
  pcs: 'pcs',
  cause_name: 'Cause name',
  Process: 'Process',
  min: 'min',
  day: 'day',
  'This month': 'This month',
  'Previous month': 'Previous month',
  'Last month': 'Last month',

  'This week': 'This week',
  'Previous week': 'Previous week',
  'Last week': 'Last week',

  Today: 'Today',
  Yesterday: 'Yesterday',

  'This year': 'This year',
  'Previous year': 'Previous year',
  'Last year': 'Last year',

  total: 'Total',
  day_time: 'day',
  change_point: 'change point',
  batch: 'batch',
  count: 'count',
  lost_group: 'Lost group',
  shift_name: 'Shift name',
  procedure: 'Procedure',
  performance: 'Performance',
  performance_percentage: 'Performance losses %',
  performance_minutes: 'Performance losses min',
  performance_batches: 'Performance losses batch',
  scrap_type: 'Type of defect',
  loading_time_minutes: 'Minute',
  net_production_amount: 'Production Amount',
  target_percentage: 'Target Performance',
  oee_percentage_by_date: 'Performance',
  oee_percentage: 'Performance',
  current_xbar: 'Current Xbar',
  previous_xbar: 'Previous XBar',
  current_xbar_avg: 'Current Xbar AVG',
  previous_xbar_avg: 'Previous Xbar AVG',
  action_line_specification: 'Action line specification',
  this_month_cp: 'This month CP',
  lot: 'Lot',
  this_month_cpk: 'This month CPK',
  avg: 'AVG',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  December: 'November',
  december: 'December',
  this_month_avg: 'This month AVG',
  last_month_avg: 'Last month AVG',
  this_month_standard_deviation: 'This month standard deviation',
  last_month_standard_deviation: 'Last month standard deviation',
  last_month_cp: 'Last month CP',
  last_month_cpk: 'Last month CPK',
  planned_down_loss_percentage: 'Planned Performance Loss',
  unplanned_down_loss_percentage: 'Unplanned Performance Loss',
  exclude_loss_percentage: 'Exclude Loss',
  other_loss_percentage: 'Other Loss',
  unknown_percentage: 'Unknown Percentage',
  data_datetime: 'Datetime',
  line_name: 'Line',
  No: 'No',
  part_number: 'Product Number',
  'product_amount/actual_production_amount_by_line': 'Production Rate',
  product_amount_percentage: 'Production Rate',
  product_amount: 'Product Amount',
  error_type: 'Change Point Of',
  scrap_category: 'Defect category',
  total_scrap_amount: 'Quantity of defective',
  total_scrap_rate_target: 'Defect target rate',
  total_scrap_rate: 'Defect rate',
  scrap_rate: 'Defect rate',
  measurement_count: 'Measurement count',
  planned_down_loss_percentage_by_date: 'Planned performance losses by date',
  unplanned_down_loss_percentage_by_date:
    'Unplanned performance losses by date',
  node_count: 'Node count',
  xbar: 'Xbars value',
  rs: 'Rs value',
  xbar_avg: 'Xbars average value',
  cp: 'CP',
  cpk: 'CPK',
  standard_deviation: 'Standard deviation',
  is_xr_meet_expect: 'Is XR meet expect?',
  staging: 'Process',
  category: 'Item',
  'deficit production amount': 'Deficit production amount',
  time_window_end_time: 'Time window end time',
  product_code: 'Product number',
  target_production_amount: 'Target production amount',
  actual_production_amount: 'Actual production amount',
  loading_time: 'Loading time',
  business_month: 'Business month',
  business_year: 'Business year',
  target_cycle_time: 'Target cycle time',
  oee: 'Performance rate',
  planned_down_loss: 'Planned performance losses',
  unplanned_down_loss: 'Unplanned performance losses',
  target_production_amount_sum: 'Target production amount (Sum)',
  actual_production_amount_sum: 'Actual production amount (Sum)',
  ot_time: 'Overtime',
  product_amount_percentage_by_date: 'Product amounth percentage by date',
  'Publish guideline successfully': 'Publish guideline successfully',
  'Publish guideline unsuccessfully': 'Publish guideline unsuccessfully',
  'Save guideline as draft successfully':
    'Save guideline as draft successfully',
  'Save guideline as draft unsuccessfully':
    'Save guideline as draft unsuccessfully',
  'Save draft confirmation': 'Save draft confirmation',
  'Publish confirmation': 'Publish confirmation',
  'Do you want to save this draft? You can continue editing later.':
    'Do you want to save this draft? You can continue editing later.',
  'Are you sure you want to save this content and publish it? Once published, the content will be applied across the system.':
    'Are you sure you want to save this content and publish it? Once published, the content will be applied across the system.',
  'Update password successfully': 'Update password successfully',
  'Update password unsuccessfully': 'Update password unsuccessfully',
  'The importing file does not match the system’s requirements':
    'The importing file does not match the system’s requirements',
  'New X-R': 'New X-R',
  'Terminologies imported successfully': 'Terminologies imported successfully',
  'Terminologies imported unsuccessfully':
    'Terminologies imported unsuccessfully',

  'Choose line': 'Choose line',
  'Choose process': 'Choose process',
  'Control chart name': 'Control chart name',
  'Control chart name ENG': 'Control chart name ENG',
  'Control chart name VIE': 'Control chart name VIE',
  'Product name': 'Product name',
  'Product line': 'Product line',
  'Product line ENG': 'Product line ENG',
  'Product line VIE': 'Product line VIE',
  'Product ID': 'Product ID',
  'Enter product ID': 'Enter product ID',
  'New product line': 'New product line',
  'Enter product line': 'Enter product line',
  'Choose product line': 'Choose product line',
  'Add new product line': 'Add new product line',
  'Terminology updated successfully': 'Terminology updated successfully',
  'Terminology updated unsuccessfully': 'Terminology updated unsuccessfully',
  '{{resource}}(s) deleted successfully':
    '{{resource}}(s) deleted successfully',
  '{{resource}} {{name}} deleted successfully':
    '{{resource}} {{name}} deleted successfully',
  '{{resource}}(s) deleted unsuccessfully':
    '{{resource}}(s) deleted unsuccessfully',
  '{{resource}} {{name}} deleted unsuccessfully':
    '{{resource}} {{name}} deleted unsuccessfully',
  'We apologize, but the system encountered an issue and could not display the chart at this time. Please try again later or contact support if the problem persists.':
    'We apologize, but the system encountered an issue and could not display the chart at this time. Please try again later or contact support if the problem persists.',
  'Sorry, the chart you selected is currently unavailable. Please choose one of the charts above':
    'Sorry, the chart you selected is currently unavailable. Please choose one of the charts above',
  'Not Delivered': 'Not Delivered',
  'Update email successfully': 'Update email successfully',
  'Update email unsuccessfully': 'Update email unsuccessfully',
  'Terminology created successfully': 'Terminology created successfully',
  'Terminology created unsuccessfully': 'Terminology created unsuccessfully',
  Login: 'Login',
  'Cancel importing data': 'Cancel importing data',
  'Download template': 'Download template',
  'Choose another file': 'Choose another file',
  'Created by': 'Created by',
  'Select file': 'Select file',
  'Skip duplicated data': 'Skip duplicated data',
  'Drag and drop data file or select from device':
    'Drag and drop data file or select from device',
  'Created time': 'Created time',
  'New process': 'New process',
  'New product': 'New product',
  'No matching item': 'No matching item',
  Upload: 'Upload',
  Group: 'Group',
  'Hi there! Do you know how to use the chatbot? If not, you can view the instructions':
    'Hi there! Do you know how to use the chatbot? If not, you can view the instructions',
  Close: 'Close',
  Here: 'Here',
  'Chatbot user guide': 'Chatbot user guide',
  'Please update the guideline content for both the English and Vietnamese versions':
    'Please update the guideline content for both the English and Vietnamese versions',
  'The chatbot can assist you quickly with the following tasks. If you encounter any issues while using the chatbot, please contact the support team for assistance.':
    'The chatbot can assist you quickly with the following tasks. If you encounter any issues while using the chatbot, please contact the support team for assistance.',
  Importing: 'Importing',
  'New terminology': 'New terminology',
  'Choose group': 'Choose group',
  'Line terminology ENG': 'Line terminology ENG',
  'Line terminology VIE': 'Line terminology VIE',
  'Enter terminology': 'Enter terminology',
  'New line': 'New line',
  'Terms and Conditions': 'Terms and Conditions',
  'Update guideline content': 'Update guideline content',
  'Guideline content': 'Guideline content',
  'New group': 'New group',
  'Add manual': 'Add manual',
  Continue: 'Continue',
  'Upload file excel': 'Upload file excel',
  'Importing data': 'Importing data',
  'Please update the upload file to .xlxs or csv format before uploading data. Maximum upload file size is 5MB':
    'Please update the upload file to .xlxs or csv format before uploading data. Maximum upload file size is 5MB',
  'All importing data from file {{fileName}} update will not be saved into system. Are you sure to continue cancel the process?':
    'All importing data from file {{fileName}} update will not be saved into system. Are you sure to continue cancel the process?',
  'Everything in control with DensoGPT': 'Everything in control with DensoGPT',
  'Welcome to chatbot': 'Welcome to chatbot',
  'Sign up': 'Sign up',
  'Product of Denso Vietnam & Fpt Software':
    'Product of Denso Vietnam & Fpt Software',
  'Create account with your email and Denso employee ID':
    'Create account with your email and Denso employee ID',
  'Enter first name': 'Enter first name',
  'Enter last name': 'Enter last name',
  'Enter email': 'Enter email',
  'Enter password': 'Enter password',
  'Enter employee ID': 'Enter employee ID',
  'Retype password': 'Retype password',
  'Forgot Password': 'Forgot Password',
  'Do not have an account?': 'Do not have an account?',
  'Create account': 'Create account',
  'Back to Login': 'Back to Login',
  'User management': 'User management',
  'Terminology management': 'Terminology management',
  'Request management': 'Request management',
  Search: 'Search',
  Role: 'Role',
  Total: 'Total',
  'Add new': 'Add new',
  Delete: 'Delete',
  Admin: 'Admin',
  User: 'User',
  Account: 'Account',
  Name: 'Name',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Employee ID': 'Employee ID',
  Action: 'Action',
  Approve: 'Approve',
  'Email address': 'Email address',
  'Incorrect format': 'Incorrect format',
  'I agree to': 'I agree to',
  'Denso GPT Terms & Private Policy': 'Denso GPT Terms & Private Policy',
  'Login successfully': 'Login successfully',
  'This field is required': 'This field is required',
  'Enter group name': 'Enter group name',
  'Group name': 'Group name',
  'Add new group': 'Add new group',
  'Invalid email/password': 'Invalid email/password',
  'Group created unsuccessfully': 'Group created unsuccessfully',
  'Group created successfully': 'Group created successfully',
  'Please input email field!': 'Please input email field!',
  'Please input password field!': 'Please input password field!',
  'Please input employee ID field!': 'Please input employee ID field!',
  'Please retype your password!': 'Please retype your password!',
  'Please input first name!': 'Please input first name!',
  'Please input last name!': 'Please input last name!',
  'Should accept agreement': 'Please accept Denso GPT Terms & Private Policy!',
  'Invalid email field': 'Invalid email field!',
  'Invalid password field': 'Invalid password field!',
  'Please enter email you registered your account, we will send an OTP your email address.':
    'Please enter email you registered your account, we will send an OTP your email address.',
  'Send OTP': 'Send OTP',
  'Email sent successfully.': 'Email sent successfully.',
  'Failed to sent email. Please try again.':
    'Failed to sent email. Please try again.',
  'Sign up request successfully!': 'Sign up request successfully!',
  'After we will send confirmation to your email address within 24h, following confirmation you can log into the system':
    'After we will send confirmation to your email address within 24h, following confirmation you can log into the system',
  'If you need a support, please': 'If you need a support, please',
  'Contact us': 'Contact us',
  'The new password that you entered do not match!':
    'The new password that you entered do not match!',
  All: 'All',
  'Are you sure to delete all selected {{resource}}?':
    'Are you sure to delete all selected {{resource}}(s)?',
  'Are you sure to delete':
    'Are you sure to delete  <strong>{{name}}</strong>??',
  'Are you sure to delete all selected requests?':
    'Are you sure to delete all selected requests?',
  'Delete request': 'Delete request',
  'Delete user': 'Delete user',
  Reset: 'Reset',
  'Reset password': 'Reset password',
  'New password': 'New password',
  'Are you sure to reset password of user':
    'Are you sure to reset password of user <strong>{{name}}</strong>?',
  'Are you sure to delete user':
    'Are you sure to delete user <strong>{{name}}</strong>?',
  'Are you sure to approve all selected requests?':
    'Are you sure to approve all selected requests?',
  'Approve request': 'Approve request',
  'Are you sure to delete all selected users?':
    'Are you sure to delete all selected users?',
  'Are you sure to approve user':
    'Are you sure to approve user <strong>{{name}}</strong>?',
  Save: 'Save',
  'Save user successfully': 'Save user successfully',
  'Create user successfully': 'Create user successfully',
  'Delete successfully': 'Delete successfully',
  'Delete unsuccessfully': 'Delete unsuccessfully',
  'Update user unsuccessfully': 'Update user unsuccessfully',
  'Create user unsuccessfully': 'Create user unsuccessfully',
  'Approve unsuccessfully': 'Approve unsuccessfully',
  'Approve successfully': 'Approve successfully',
  'Create password unsuccessfully': 'Create password unsuccessfully',
  'Register successfully': 'Register successfully',
  'Register unsuccessfully': 'Register unsuccessfully',
  'Reset password successfully': 'Reset password successfully',
  Rename: 'Rename',
  'Update personal information': 'Update personal information',
  'First name': 'First name',
  'Last name': 'Last name',
  'Current password': 'Current password',
  'Retype new password': 'Retype new password',
  'Type Message...': 'Type Message...',
  'Are you sure to delete this conversation?':
    'Are you sure to delete this conversation?',
  'Delete conversation successfully': 'Delete conversation successfully',
  'Delete conversation unsuccessfully': 'Delete conversation unsuccessfully',
  'Rename conversation successfully': 'Rename conversation successfully',
  'Rename conversation unsuccessfully': 'Rename conversation unsuccessfully',
  'Delete conversation': 'Delete conversation',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  Password: 'Password',
  Product: 'Product',
  'Update user successfully': 'Update user successfully',
  'Part number': 'Part number',
  'Send test email': 'Send test email',
  'Please enter the OTP sent to your email {{email}} to reset password':
    'Please enter the OTP sent to your email <strong>{{email}}</strong> to reset password',
  Resend: 'Resend',
  'The old password is incorrect': 'The old password is incorrect',
  Line: 'Line',
  'Line group': 'Line group',
  'Line group ENG': 'Line group ENG',
  'Line group VIE': 'Line group VIE',
  'Edit terminology': 'Edit terminology',
  'Choose line group': 'Choose line group',
  'Process terminology ENG': 'Process terminology ENG',
  'Process terminology VIE': 'Process terminology VIE',
  'Update email': 'Update email',
  'Are you sure to save the update?': 'Are you sure to save the update?',
  'Failed to send email. Please recheck the email address and password':
    'Failed to send email. Please recheck the email address and password',
  'The test email was sent successfully!':
    'The test email was sent successfully!',
  'The test email was sent unsuccessfully!':
    'The test email was sent unsuccessfully!',
  'Failed to send email. Please try again later':
    'Failed to send email. Please try again later',
  'The inputed password does not match requirements':
    'The inputed password does not match requirements',
  'Can not connect to SMTP server': 'Can not connect to SMTP server',
  'OTP after': 'OTP after',
  'Please enter your new password': 'Please enter your new password',
  'Incorrect OTP, please recheck it': 'Incorrect OTP, please recheck it',
  'Update system email': 'Update system email',
  'Personal information': 'Personal information',
  'Log out': 'Logout',
  'Add new user': 'Add new user',
  'Edit user information': 'Edit user information',
  'Password must be at least 8 characters long.':
    'Password must be at least 8 characters long.',
  'The {{name}} password has been reset as below:':
    'The <strong>{{name}}</strong> password has been reset as below:',
  'It must include at least one uppercase letter, one lowercase letter, and one number.':
    'It must include at least one uppercase letter, one lowercase letter, and one number.',
  'Everything now in your control with DensoGPT':
    'Everything now in your control with DensoGPT',
  // eslint-disable-next-line quotes
  'Lets start!': "Let's start!",
  '+ New chat': '+ New chat',
  'Bar chart': 'Bar',
  scrap_amount: 'Srap amount',
  'Line chart': 'Line',
  Table: 'Table',
  'Pie chart': 'Pie',
  'Pareto chart': 'Pareto',
  Ownership: 'Ownership',
  'DENSO MANUFACTURING VIETNAM CO., LTD':
    'DENSO MANUFACTURING VIETNAM CO., LTD',
  'Monday to Friday, 8:10 AM ~ 5:15 PM': 'Monday to Friday, 8:10 AM ~ 5:15 PM',
  'Information Security': 'Information Security',
  'Phone number': 'Phone number',
  'Working hours': 'Working hours',
  Department: 'Department',
  Section: 'Section',
  'You commit to keeping confidential and secure all login information, account passwords, and any information provided directly or indirectly by the Denso GPT software. This information is considered confidential and proprietary to Denso Manufacturing Vietnam and is only allowed for internal use within the company. You are responsible for any activities that occur on your account. If you detect any suspicious activity or unauthorized access to your account, you must immediately notify DMVN PE.':
    'You commit to keeping confidential and secure all login information, account passwords, and any information provided directly or indirectly by the Denso GPT software. This information is considered confidential and proprietary to Denso Manufacturing Vietnam and is only allowed for internal use within the company. You are responsible for any activities that occur on your account. If you detect any suspicious activity or unauthorized access to your account, you must immediately notify DMVN PE.',
  'Usage Rights': 'Usage Rights',
  'Contact Information': 'Contact Information',
  'Plot E-1, Thang Long Industrial Park, Kim Chung Commune, Dong Anh District, Hanoi, Vietnam':
    'Plot E-1, Thang Long Industrial Park, Kim Chung Commune, Dong Anh District, Hanoi, Vietnam',
  'Company name': 'Company name',
  'By registering an account, you are granted the right to use this software for your personal or work-related purposes within the permitted scope. You are not allowed to use the software for any commercial or illegal purposes.':
    'By registering an account, you are granted the right to use this software for your personal or work-related purposes within the permitted scope. You are not allowed to use the software for any commercial or illegal purposes.',
  'This software and all related documents are the property of the Production Engineering Department and are protected by intellectual property rights. You are not allowed to copy, distribute, rent, sublet, sell, transfer, or modify the software or any related documents without written permission from the Production Engineering Department.':
    'This software and all related documents are the property of the Production Engineering Department and are protected by intellectual property rights. You are not allowed to copy, distribute, rent, sublet, sell, transfer, or modify the software or any related documents without written permission from the Production Engineering Department.',
  'Welcome to the registration for using the Denso GPT software by the Production Engineering Department of Denso Manufacturing Vietnam (hereinafter referred to as the Production Engineering Department). Before continuing to use the software, you need to read and understand the following Terms and Conditions. Using this software means that you accept and comply with the following terms and conditions:':
    'Welcome to the registration for using the Denso GPT software by the Production Engineering Department of Denso Manufacturing Vietnam (hereinafter referred to as the Production Engineering Department). Before continuing to use the software, you need to read and understand the following Terms and Conditions. Using this software means that you accept and comply with the following terms and conditions:',
};
