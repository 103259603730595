import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';

import CheckIcon from 'assets/icons/check-circle-fill.png';
import { SolidBtn } from 'components/Buttons';
import { ForgotPasswordWrapper } from '../style';

export function SuccessChangePassword() {
  const { t } = useTranslation();

  return (
    <ForgotPasswordWrapper>
      <p className="img-area">
        <img src={CheckIcon} alt="checkicon" width={68} />
      </p>
      <p className="title">{t('Reset password successfully')}</p>
      <Flex style={{ paddingTop: 20 }} justify="center">
        <SolidBtn
          style={{ padding: '12px 24px' }}
          href="/login"
          type="primary"
          htmlType="submit"
        >
          {t('Back to Login')}
        </SolidBtn>
      </Flex>
    </ForgotPasswordWrapper>
  );
}
