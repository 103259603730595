import { useTranslation } from 'react-i18next';

export type ErrorKey = 'exist' | 'invalid';

export type ErrorType = Record<string, Record<ErrorKey, string>[]>;

export const useErrors = () => {
  const { t } = useTranslation();

  const mapToFormErrors = (errors: ErrorType) =>
    Object.entries(errors).map(([key, err]) => {
      const firstError = err[0];

      const firstNestedError = Object.keys(firstError)[0];

      const displayedError = t(`errors.${firstNestedError}.${key}`);

      return {
        name: key,
        errors: [displayedError],
      };
    });

  return {
    mapToFormErrors,
  };
};
