import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: 500px;
  padding: 40px;
  .title {
    font-size: 40px;
    font-weight: 600;
    color: #003a8c;
    margin-bottom: 10px;
    position: relative;
    margin-top: 52px;
    display: inline-block;
    img {
      position: absolute;
      top: -15px;
      right: -30px;
    }
  }
  .sub-title {
    font-size: 20px;
    font-weight: 500;
    color: #003a8c;
    margin-bottom: 36px;
    line-height: 28px;
  }

  .ant-input-affix-wrapper {
    padding: 17px 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .button-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
  }

  .footer {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-top: 36px;
  }
`;
