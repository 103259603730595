import { useState } from 'react';
import { ScreenState } from './types';
import {
  CreateNewPassword,
  SendEmail,
  SendOTP,
  SuccessChangePassword,
} from './components';
import AuthenLayout from '../../layouts/AuthenLayout';

function ForgotPassword() {
  const [screen, setScreen] = useState<ScreenState>(ScreenState.SendEmail);

  return (
    <AuthenLayout>
      {screen === ScreenState.SendEmail && <SendEmail setScreen={setScreen} />}
      {screen === ScreenState.SendOTP && <SendOTP setScreen={setScreen} />}
      {screen === ScreenState.SendPassword && (
        <CreateNewPassword setScreen={setScreen} />
      )}
      {screen === ScreenState.SuccessChangePassword && (
        <SuccessChangePassword />
      )}
    </AuthenLayout>
  );
}

export default ForgotPassword;
