import type { ProgressProps } from 'antd';
import { Flex, Progress, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface CustomProgressProps extends ProgressProps {
  fileName?: string;
}

export function CustomProgress(props: CustomProgressProps) {
  const { percent, fileName } = props;
  const { t } = useTranslation();

  return (
    <Flex gap={16} vertical>
      <Progress size={{ height: 12 }} percent={percent} showInfo={false} />
      <Flex justify="space-between">
        <Typography.Text style={{ fontWeight: 700, color: '#595959' }}>
          {fileName}
        </Typography.Text>
        <Typography.Text style={{ fontWeight: 700 }}>
          {t('Importing')} {percent}%
        </Typography.Text>
      </Flex>
    </Flex>
  );
}
