import React, { useEffect, useState } from 'react';

interface Props {
  initialSeconds: number;
  callback: () => void;
}

export function CountdownTimer({ initialSeconds, callback }: Props) {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Cleanup the interval on component unmount
      return () => clearInterval(timerId);
    }

    return callback();
  }, [seconds]);

  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const secondsRemainder = totalSeconds % 60;

    return `${minutes}:${secondsRemainder < 10 ? '0' : ''}${secondsRemainder}s`;
  };

  return (
    <div>
      <div className="timer-content">{formatTime(seconds)}</div>
    </div>
  );
}
