import { Modal } from 'antd';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 12px;
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #262626;
  }
  .content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #262626;
    padding: 14px 0;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
  }
`;
