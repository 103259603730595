import type {
  CreateLineGroupReqDTO,
  CreateProductLineReqDTO,
  ILineGroup,
  IParam,
  IProductLine,
  Pagination,
  TerminologyPayloadByType,
  TerminologyResponseByType,
} from 'types';
import type { TerminologyType } from 'types/terminology';
import type { UploadFile } from 'antd';
import { axiosInstance } from '../utils/axios';

const resourceEndpoint = '/api/terminology';

const terminologyTypeEndpoint: Record<TerminologyType, string> = {
  Line: 'line',
  Process: 'processequipment',
  Product: 'product',
  XR: 'xr',
};

export const createLineGroup = (payload: CreateLineGroupReqDTO) =>
  axiosInstance.post<never, ILineGroup>(
    `${resourceEndpoint}/linegroup`,
    payload,
  );

export const createProductLine = (payload: CreateProductLineReqDTO) =>
  axiosInstance.post<never, IProductLine>(
    `${resourceEndpoint}/productline`,
    payload,
  );

export const getLineGroups = (params?: IParam) =>
  axiosInstance.get<never, ILineGroup[]>(`${resourceEndpoint}/linegroup`, {
    params,
  });

export const getProductLines = (params?: IParam) =>
  axiosInstance.get<never, IProductLine[]>(`${resourceEndpoint}/productline`, {
    params,
  });

export const getTerminologiesByType = <T extends TerminologyType>(
  type: TerminologyType,
  params?: IParam,
) =>
  axiosInstance.get<never, Pagination<TerminologyResponseByType<T>>>(
    `${resourceEndpoint}/${terminologyTypeEndpoint[type]}`,
    {
      params,
    },
  );

export const getTerminologyByType = <T extends TerminologyType>(
  type: TerminologyType,
  id: number,
) =>
  axiosInstance.get<never, TerminologyResponseByType<T>>(
    `${resourceEndpoint}/${terminologyTypeEndpoint[type]}/${id}`,
  );

export const createTerminologyByType = <T extends TerminologyType>(
  type: TerminologyType,
  payload: TerminologyPayloadByType<T>,
) =>
  axiosInstance.post<never, TerminologyPayloadByType<T>>(
    `${resourceEndpoint}/${terminologyTypeEndpoint[type]}`,
    payload,
  );

export const updateTerminologyByType = <T extends TerminologyType>(
  type: TerminologyType,
  id: number,
  payload: TerminologyPayloadByType<T>,
) =>
  axiosInstance.put<never, TerminologyPayloadByType<T>>(
    `${resourceEndpoint}/${terminologyTypeEndpoint[type]}/${id}/`,
    payload,
  );

export const deleteTerminologiesByType = (
  type: TerminologyType,
  ids: number[],
) =>
  axiosInstance.delete<never, never>(
    `${resourceEndpoint}/${terminologyTypeEndpoint[type]}/delete`,
    { data: { ids } },
  );

interface UploadProgressEvent extends Partial<ProgressEvent> {
  percent?: number;
}

export const uploadFileByType = (
  type: TerminologyType,
  file: UploadFile,
  skipDuplicated: boolean,
  onProgress: (event: UploadProgressEvent) => void,
) => {
  const formData = new FormData();

  formData.append('file', file.originFileObj as never);
  formData.append('skip_duplicated', skipDuplicated.toString());

  return axiosInstance.post<never, never>(
    `${resourceEndpoint}/${terminologyTypeEndpoint[type]}/upload/${file.name}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / Number(progressEvent.total),
        );

        onProgress?.({ percent: percentCompleted });
      },
    },
  );
};

export const downloadFileByType = (type: TerminologyType) =>
  axiosInstance.get<never, never>(
    `${resourceEndpoint}/${terminologyTypeEndpoint[type]}/downloadtemplate`,
    { responseType: 'blob' },
  );
