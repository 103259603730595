import type { MouseEventHandler } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Flex, message, Typography } from 'antd';

import type { MenuItemType } from 'antd/es/menu/interface';
import { ConfirmModal } from 'components';
import { deleteConversation } from 'services/conversation.service';
import type { IConversation } from 'types';
import { RenameModal } from './Modals';

interface MenuItemProp {
  item: IConversation;
  refetch: (id?: number) => Promise<void>;
  onConversationClick: () => void;
}

function MenuItem({ item, refetch, onConversationClick }: MenuItemProp) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showDropdown, setshowDropdown] = useState(false);
  const { t } = useTranslation();

  const [isRenameModalOpen, toggleRenameModal] = useState(false);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);

  const deleteData = async (id: IConversation['id']) => {
    try {
      toggleDeleteModal(false);

      await deleteConversation(id);

      await refetch(id);
      message.success(t('Delete conversation successfully'));
    } catch (error) {
      message.error(t('Delete conversation unsuccessfully'));
    }
  };

  const handleDeleteClick: MenuItemType['onClick'] = () => {
    toggleDeleteModal(true);
  };

  const handleRenameClick: MenuItemType['onClick'] = () => {
    toggleRenameModal(true);
  };

  const items: MenuProps['items'] = [
    {
      label: <Typography.Text>{t('Rename')}</Typography.Text>,
      key: '0',
      style: { height: 36 },
      onClick: handleRenameClick,
    },
    {
      label: <Typography.Text type="danger">{t('Delete')}</Typography.Text>,
      key: '1',
      style: { height: 36 },
      onClick: handleDeleteClick,
    },
  ];

  const handleOnClick: MouseEventHandler<HTMLElement> = (e) => {
    if (e.target === e.currentTarget) onConversationClick();
  };

  return (
    <Flex
      justify="space-between"
      align="center"
      onMouseEnter={() => setshowDropdown(true)}
      onMouseLeave={() => setshowDropdown(false)}
      onClick={handleOnClick}
      style={{
        padding: 16,
        gap: 16,
        marginInline: 0,
        height: 52,
        width: '100%',
        fontWeight: 500,
        boxSizing: 'border-box',
        boxShadow: '0px 1px 6px -32px #0050891A',
      }}
    >
      <Typography.Text
        onClick={handleOnClick}
        style={{ width: '85%' }}
        ellipsis
      >
        {item.conversation_name}
      </Typography.Text>
      <Dropdown
        menu={{ items, style: { padding: 8 } }}
        trigger={['click']}
        placement="bottomRight"
        open={isDropdownOpen}
        onOpenChange={setIsDropdownOpen}
        overlayStyle={{ width: 174, paddingTop: 8 }}
      >
        <Button
          hidden={!showDropdown && !isDropdownOpen}
          type="text"
          shape="circle"
          icon={<EllipsisOutlined />}
        />
      </Dropdown>
      <ConfirmModal
        content={t('Are you sure to delete this conversation?')}
        handleOk={() => deleteData(item.id)}
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={toggleDeleteModal}
        title={t('Delete conversation')}
        okBtn={t('Delete')}
        cancelBtn={t('Cancel')}
      />
      <RenameModal
        item={item}
        refetch={refetch}
        open={isRenameModalOpen}
        toggleOpen={toggleRenameModal}
      />
    </Flex>
  );
}

export default MenuItem;
