/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  MentionProps,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions';
import { Mention, MentionsInput } from 'react-mentions';
import { Flex, Popover, Typography } from 'antd';
import debounce from 'lodash.debounce';

import BlueArrorRight from 'assets/icons/blue-arrow-right.png';
import { getTerminologies } from 'services/terminology.service';

import { sendMessage } from 'services/chat.service';
import { useChatStore } from 'stores/useChatStore';
import { createConversation } from 'services/conversation.service';
import type { GetMessageResDTO } from 'types/dto/responses/message';
import type { IConversation } from 'types';
import { parseJSONToTableChart } from 'utils';
import { generateUniqueConversationName } from 'pages/Chat/utils';
import { mentionItemStyle, mentionStyle } from './style';

import classNames from './style.module.scss';

interface ChatBarProps {
  refetch: () => Promise<IConversation[]>;
}
function ChatBar({ refetch }: ChatBarProps) {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const {
    toggleIsSending,
    isSending,
    updateLastMessage,
    insertMessage,
    curConversation,
    setCurConversation,
    conversations,
  } = useChatStore();

  const [shouldFetch, toggleShouldFetch] = useState(true);
  const [emptyPopup, toggleEmptyPopup] = useState(false);

  const handleSendMessage = async () => {
    if (isSending) return;
    const trimmiedMessage = message.trim();

    if (!trimmiedMessage) return;
    toggleIsSending(true);
    setMessage('');

    const sanitizeMessage = trimmiedMessage.replace(/@\[(.+?)\]\(.+?\)/g, '$1');

    let conversationId = curConversation?.id ?? 0;
    let newMessage = null;

    insertMessage({
      id: Date.now(),
      question: sanitizeMessage,
      chartType: '',
      conversationId: -1,
      language: 'en',
      timeLevelOptions: [],
      selectTimeLevelOption: '',
      metaData: {},
      correctQuestion: '',
    });

    try {
      if (!curConversation?.id) {
        const conversation = await createConversation({
          conversation_name: generateUniqueConversationName(conversations),
        });

        conversationId = conversation.id;

        newMessage = await sendMessage(conversationId, {
          message: sanitizeMessage,
        });

        setCurConversation(conversation);
        await refetch();
      } else {
        newMessage = await sendMessage(conversationId, {
          message: sanitizeMessage,
        });
      }

      console.info({
        'Câu hỏi gốc': newMessage.question,
        'Câu hỏi đã sửa': newMessage.correctQuestion,
        'Text SQL': newMessage.textSql,
        'Chart SQL':
          newMessage.metaData[newMessage.selectTimeLevelOption as never]
            ?.chart_sql,
      });

      const [_, parsedTime] = parseJSONToTableChart(
        newMessage.timeLevelOptions,
      );

      updateLastMessage({
        ...newMessage,
        question: sanitizeMessage,
        error: newMessage.error,
        timeLevelOptions: parsedTime,
      });

      toggleIsSending(false);
    } catch (err) {
      console.log(err);

      const messageFromError = err as GetMessageResDTO;

      toggleIsSending(false);

      const [parsedMetadata, parsedTime] = parseJSONToTableChart(
        messageFromError.timeLevelOptions,
        messageFromError.metaData,
      );

      updateLastMessage({
        ...messageFromError,
        question: sanitizeMessage,
        error: messageFromError.error,
        timeLevelOptions: parsedTime,
        metaData: parsedMetadata,
      });
    }
  };

  const handleKeyDown: MentionsInputProps['onKeyDown'] = (e) => {
    if (isSending || e.getModifierState('Shift')) return;
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleChange: MentionsInputProps['onChange'] = (e) => {
    let { value } = e.target;

    if (value.length > 300) value = value.slice(0, 300);

    setMessage(value);
  };

  const fethMentions = debounce(
    async (
      metionString: string,
      searchFn: (data: SuggestionDataItem[]) => void,
    ) => {
      try {
        const result = await getTerminologies({ search: metionString });

        if (result.terminology_names.length === 0) {
          toggleEmptyPopup(true);

          return;
        }

        const mappedData: SuggestionDataItem[] = result.terminology_names.map(
          (item) => ({
            id: item,
            display: item,
          }),
        );

        searchFn(mappedData);
        toggleEmptyPopup(false);
      } catch (err) {
        toggleEmptyPopup(true);
      }
    },
    300,
  );

  const handleData: MentionProps['data'] = (searchString, searchFn) => {
    if (searchString.trim() === '') toggleShouldFetch(true);
    if (!shouldFetch) return;
    fethMentions(searchString, searchFn);
  };

  const handleBlur = () => {
    toggleEmptyPopup(false);
    toggleShouldFetch(false);
  };

  return (
    <Flex
      style={{
        padding: '0px 24px 0px 32px',
        borderRadius: 100,
        border: '1px solid #D2EAF4',
        boxShadow: '0px 2px 12px 0px #00407C14',
        backgroundColor: '#F5F5F5',
        resize: 'vertical',
        minWidth: 700,
        width: '55%',
      }}
      align="center"
      justify="space-around"
      gap={16}
    >
      <Popover
        content={
          <Typography.Text style={{ fontWeight: 500, padding: 16 }}>
            {t('No matching results')}
          </Typography.Text>
        }
        trigger="focus"
        open={emptyPopup}
        placement="topLeft"
        arrow={false}
      >
        <MentionsInput
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('Type Message...')}
          allowSuggestionsAboveCursor={true}
          forceSuggestionsAboveCursor={true}
          style={mentionStyle}
          classNames={classNames}
          className="mentions"
          onBlur={handleBlur}
          allowSpaceInQuery
          maxLength={300}
        >
          <Mention
            trigger="@"
            data={handleData}
            style={mentionItemStyle}
            className={classNames.mentions__mention}
          />
        </MentionsInput>
      </Popover>

      <img
        style={{
          cursor: isSending ? 'not-allowed' : 'pointer',
          filter: isSending ? 'grayscale(100%)' : 'none',
        }}
        src={BlueArrorRight}
        alt="submit"
        width={23.5}
        height={22.5}
        onClick={handleSendMessage}
      />
    </Flex>
  );
}

export default ChatBar;
