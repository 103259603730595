import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { FormProps } from 'antd';
import { Form, notification, Spin } from 'antd';

import { useState } from 'react';
import Questionmark from 'assets/icons/questionmark-circle-fill.png';
import { AuthenInput, AuthenLink } from 'layouts/AuthenLayout/style';
import { forgotPassword } from 'services/auth.service';
import { SolidBtn } from 'components/Buttons';
import { ForgotPasswordWrapper } from '../style';
import { ScreenState } from '../types';

type FieldType = {
  email: string;
};

interface SendEmailProps {
  setScreen: (screen: ScreenState) => void;
}

export function SendEmail({ setScreen }: SendEmailProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      setIsLoading(true);
      await forgotPassword(values);
      setIsLoading(false);
      navigate(`/forgot-password?email=${values.email}`);
      setScreen(ScreenState.SendOTP);
      notification.success({
        message: t('Email sent successfully.'),
      });
    } catch (error) {
      setIsLoading(false);

      notification.error({
        message: t('errors.not_exist.email'),
      });
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo,
  ) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <ForgotPasswordWrapper>
      <Spin spinning={isLoading}>
        <p className="img-area">
          <img src={Questionmark} alt="Questionmark" width={68} />
        </p>
        <p className="title">{t('Forgot Password')}</p>
        <p className="sub-title">
          {t(
            'Please enter email you registered your account, we will send an OTP your email address.',
          )}
        </p>
        {/* <button onClick={() => changeLanguage("vn")}>vn</button>
        <button onClick={() => changeLanguage("en")}>en</button> */}
        <Form
          form={form}
          name="forgot-password-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            name="email"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('Please input email field!'),
              },
              { type: 'email', message: t('Invalid email field') },
            ]}
          >
            <AuthenInput placeholder={`${t('Enter email')} *`} />
          </Form.Item>

          <div className="button-area">
            <AuthenLink to="/login">{t('Back to Login')}</AuthenLink>
            <SolidBtn type="primary" htmlType="submit">
              {t('Reset password')}
            </SolidBtn>
          </div>
        </Form>
      </Spin>
    </ForgotPasswordWrapper>
  );
}
