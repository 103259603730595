import type { IUser, LanguageType, LoginResDTO } from 'types';
import { axiosInstance, axiosNoAuthInstance } from '../utils/axios';

export const register = (params?: {
  first_name?: string;
  last_name?: string;
  email: string;
  employee_id: string;
  password: string;
  language: LanguageType;
}) => axiosInstance.post<never, IUser>('/api/user/registration/', params);

export const forgotPassword = (params?: { email: string }) =>
  axiosInstance.post<never, IUser>('/api/user/forgotpassword/', params);

export const verifyOTP = (params?: { email: string; otp: string }) =>
  axiosInstance.post<never, IUser>('/api/user/resetpasswordotp/', params);

export const resetPassword = (params?: {
  email: string;
  otp: string;
  new_password: string;
}) => axiosInstance.put<never, IUser>('/api/user/resetpasswordotp/', params);

export const login = (username: string, password: string) =>
  axiosNoAuthInstance.post<never, LoginResDTO>('/api/login/', {
    username,
    password,
  });
