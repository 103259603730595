import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RightSideHeader } from 'components';
import Logo from 'assets/images/logo-authen.png';
import LogoDensoXFpt from 'assets/images/logo-densoxfpt.png';

import { AuthenWrapper } from './style';

type Props = {
  children: React.ReactNode;
};

function AuthenLayout({ children }: Props) {
  const { t } = useTranslation();

  return (
    <AuthenWrapper>
      <div className="authen-header">
        <Link to="/">
          <img src={Logo} alt="" width={144} />
        </Link>
        <RightSideHeader />
      </div>
      <div className="authen-content">{children}</div>
      <div className="authen-footer">
        <span>{t('Product of Denso Vietnam & Fpt Software')} </span>
        <img src={LogoDensoXFpt} alt="" width={86.5} />
      </div>
    </AuthenWrapper>
  );
}

export default AuthenLayout;
