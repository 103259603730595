import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, notification, Spin } from 'antd';
import type { InputOTPProps } from 'antd-input-otp';
import { InputOTP } from 'antd-input-otp';

import { AuthenLink } from 'layouts/AuthenLayout/style';
import { forgotPassword, verifyOTP } from 'services/auth.service';
import { TextButton } from '../../UserManagement/components/style';
import { CreateNewPasswordWrapper } from '../style';
import { ScreenState } from '../types';
import { CountdownTimer } from './CountdownTimer';

interface SendOTPProps {
  setScreen: (screen: ScreenState) => void;
}

const DefaultTimer = 300;

export function SendOTP({ setScreen }: SendOTPProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [timer, setTimer] = useState<number>(DefaultTimer);
  const [otpError, setOtpError] = useState(false);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const email = searchParams.get('email') ?? '';

  const handleResetClick = async () => {
    try {
      if (!email) return;
      setIsLoading(true);

      await forgotPassword({ email });
      setIsLoading(false);

      setTimer(DefaultTimer);
      notification.success({
        message: t('Email sent successfully.'),
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  const timeout = () => {
    setTimer(0);
  };

  const handleSubmit: InputOTPProps['autoSubmit'] = async (otp: string[]) => {
    try {
      const joinedOtp = otp.join('');

      await verifyOTP({ email, otp: joinedOtp });
      navigate(`/forgot-password?email=${email}&otp=${otp.join('')}`);
      setScreen(ScreenState.SendPassword);
    } catch (err) {
      setOtpError(true);
    }
  };

  return (
    <CreateNewPasswordWrapper>
      <Spin spinning={isLoading}>
        <p className="title">{t('Reset password')}</p>
        <p className="sub-title">
          <Trans
            i18nKey="Please enter the OTP sent to your email {{email}} to reset password"
            values={{ email }}
          >
            Please enter the OTP sent to your email <strong>{email}</strong> to
            reset password
          </Trans>
        </p>
        <InputOTP
          length={6}
          placeholder="_"
          inputStyle={{
            color: 'red',
            fontWeight: 700,
            borderColor: otpError ? '#FFA39E' : '#BFBFBF',
            width: 40,
            height: 40,
            borderRadius: 8,
            fontSize: 24,
          }}
          inputType="numeric"
          autoSubmit={handleSubmit}
        />
        {otpError && (
          <Flex justify="center" style={{ color: 'red', marginTop: 36 }}>
            {t('Incorrect OTP, please recheck it')}
          </Flex>
        )}
        <div className="button-area">
          <AuthenLink to="/login">{t('Back to Login')}</AuthenLink>
          <div className="countdown-area">
            <TextButton
              style={{ color: '#096DD9', fontSize: '14px' }}
              onClick={handleResetClick}
              disabled={!!timer}
            >
              {t('Resend')}
            </TextButton>
            <span style={{ padding: '0 4px' }}> {t('OTP after')}</span>
            <CountdownTimer initialSeconds={timer} callback={timeout} />
          </div>
        </div>
      </Spin>
    </CreateNewPasswordWrapper>
  );
}
