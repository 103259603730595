import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CustomResult } from './style';

export enum ErrorMessage {
  SMTPConnection = 'Can not connect to SMTP server',
  Misconfiguration = 'Can not connect to SMTP server',
  Unknown = 'Failed to send email. Please try again later',
  Credential = 'Failed to send email. Please recheck the email address and password',
}

interface EmailResultProps {
  isSuccess: boolean;
  errorMsg?: ErrorMessage | null;
}

export function EmailResult({ isSuccess, errorMsg }: EmailResultProps) {
  const { t } = useTranslation();

  return (
    <CustomResult
      status={isSuccess ? 'success' : 'error'}
      title={
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          {isSuccess
            ? t('The test email was sent successfully!')
            : t('The test email was sent unsuccessfully!')}
        </Typography.Title>
      }
      subTitle={
        <Typography.Text color="#595959">
          {!isSuccess && errorMsg
            ? t(errorMsg)
            : t('You can check the mailbox')}
        </Typography.Text>
      }
      style={{
        border: '1px dashed #69C0FF',
        borderRadius: 16,
        backgroundColor: '#F5F5F5',
      }}
      icon={
        isSuccess ? (
          <CheckCircleFilled style={{ fontSize: 24 }} />
        ) : (
          <CloseCircleFilled style={{ fontSize: 24 }} />
        )
      }
    />
  );
}
