import { Link } from 'react-router-dom';
import { Input } from 'antd';
import styled from 'styled-components';

import BgAuthen from 'assets/images/bg-authen.png';

export const AuthenWrapper = styled.div`
  display: block;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #ecf5fc;

  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus,
  .ant-input-outlined.ant-input-status-error:not(
      .ant-input-disabled
    ):focus-within,
  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within {
    box-shadow: none;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${BgAuthen});
    background-size: 82%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
  .authen-header {
    z-index: 1;
    display: flex;
    position: relative;
    padding: 40px 80px;
    justify-content: space-between;
    align-items: center;
  }

  .authen-content {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0px 4px 20px 0px #0023a126;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
  }

  .authen-footer {
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 16px;
    display: flex;
    align-items: end;
    span {
      margin-right: 8px;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const AuthenInput = styled(Input)`
  padding: 17px 20px;
  font-size: 16px;
  font-weight: 400;
`;

export const AuthenLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  text-decoration: none;
  color: #096dd9;
`;
