import { Typography } from 'antd';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { CustomModal } from '../CustomModal';

interface DeleteModalProps<T extends object> {
  open: boolean;
  toggleOpen: (value: boolean) => void;
  refetch: () => Promise<void>;
  selectedRecords: T[];
  nameField: keyof T;
  resourceName: string;
  onDelete: (ids: number[], name: string) => Promise<void>;
  resetSingleState?: () => void;
}

export function DeleteModal<T extends object & { id: number }>({
  open,
  refetch,
  resourceName,
  toggleOpen,
  selectedRecords,
  nameField,
  onDelete,
  resetSingleState,
}: DeleteModalProps<T>) {
  const isBulkDeletion = selectedRecords.length > 1;

  const singleNameRecord =
    selectedRecords.length > 0 ? selectedRecords[0][nameField] : '';

  const handleClose = () => {
    resetSingleState?.();
    toggleOpen(false);
  };

  const handleOk = () => {
    const ids = selectedRecords.map((item) => item.id);

    onDelete(ids, singleNameRecord as string)
      .then((res) => {
        handleClose();
        refetch();

        return res;
      })
      .catch(() => {});
  };

  return (
    <CustomModal
      open={open}
      title={
        <div style={{ textAlign: 'start' }}>
          {`${t('Delete')} ${resourceName.toLocaleLowerCase()}`}
        </div>
      }
      onOk={handleOk}
      onCancel={handleClose}
      onClose={handleClose}
      okText={t('Delete')}
    >
      <Typography.Text>
        {isBulkDeletion ? (
          t('Are you sure to delete all selected {{resource}}?', {
            resource: resourceName.toLocaleLowerCase(),
          })
        ) : (
          <Trans
            i18nKey="Are you sure to delete"
            values={{ name: singleNameRecord }}
          >
            Are you sure to delete <strong>{singleNameRecord as string}</strong>
            ?
          </Trans>
        )}
      </Typography.Text>
    </CustomModal>
  );
}
