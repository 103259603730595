import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DoubleLeftOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Badge, Tabs } from 'antd';

import { useTranslation } from 'react-i18next';
import { RightSideHeader } from 'components';
import { getUserRequests } from 'services/requestManagement.service';
import UserManagement from './components/UserManagement';

import RequestManagement from './components/RequestManagement';
import { UserManagementWrapper } from './style';

function UserManagementTabs() {
  const [activeTab, setActiveTab] = useState('1');
  const [total, setTotalRequest] = useState<number>(0);
  const { t } = useTranslation();
  const onChange = (key: string) => {
    console.log(key);
    setActiveTab(key);
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const result = await getUserRequests();

      setTotalRequest(result.count);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('User management'),
      children: <UserManagement activeTab={activeTab} />,
    },
    {
      key: '2',
      label: (
        <div>
          {t('Request management')}{' '}
          {total > 0 && (
            <Badge
              count={total}
              size="small"
              color={activeTab === '2' ? '#f5222d' : '#8C8C8C'}
              style={{
                padding: '0px 4px',
                fontSize: '12px',
                lineHeight: '18px',
                height: '18px',
                borderRadius: '8px',
                minWidth: '18px',
                textAlign: 'center',
              }}
            />
          )}
        </div>
      ),
      children: (
        <RequestManagement
          setTotalRequest={setTotalRequest}
          activeTab={activeTab}
        />
      ),
    },
  ];

  return (
    <UserManagementWrapper>
      <div className="header">
        <div className="left-side">
          <Link to="/chat">
            <DoubleLeftOutlined
              style={{ fontSize: '24px', color: '#262626' }}
            />
          </Link>
          <span className="title">{t('User management')}</span>
        </div>
        <div className="right-side">
          <RightSideHeader />
        </div>
      </div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        className="custom-tabs"
      />
    </UserManagementWrapper>
  );
}

export default UserManagementTabs;
