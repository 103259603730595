import { countColumnRegex } from 'constants/regex';
import type { TFunction } from 'i18next';
import type {
  GetMessageResDTO,
  IConversation,
  IMessage,
  LanguageType,
} from 'types';
import { parseJSONToTableChart } from 'utils';
import { parseMonth } from 'utils/time';

export const generateUniqueConversationName = (
  conversations: IConversation[],
): string => {
  let name = `New Conversation ${conversations.length + 1}`;
  let count = 0;

  const conversationNameMap = new Map(
    conversations.map((item) => [item.conversation_name, 1]),
  );

  while (conversationNameMap.has(name)) {
    conversationNameMap.set(name, 1);
    count += 1;
    name = `${name.replace(/\.\d+/g, '')}.${count}`;
  }

  return name;
};

export const translateCountColumnTitle = (
  columnName: string,
  t: TFunction<'translation', undefined>,
  lang: LanguageType,
) => {
  let res = '';
  const match = columnName.match(countColumnRegex);

  if (match)
    res = `${t('Total', { lng: lang })} ${t(match[1], { lng: lang }).toLocaleLowerCase()}`;
  else res = t(`${columnName}`, { lng: lang });

  return res;
};

export const translateMonthColumnTitle = (
  columnName: string,
  t: TFunction<'translation', undefined>,
  lang: LanguageType,
) => {
  const regex = /^([A-Za-z]+)_(.+)$/;

  const match = columnName.match(regex);

  if (match) {
    const [_, month, rest] = match;

    const monthInNumber = parseMonth(month);

    if (!monthInNumber) return columnName;
    if (lang === 'vn')
      return `${t(rest, { lng: lang })} tháng ${monthInNumber}`;

    return `${t(rest, { lng: lang })} in ${month}`;
  }

  return columnName;
};

export const extractColumnFromCountColumn = (columnName?: string): string => {
  if (!columnName) return '';
  const match = columnName.match(countColumnRegex);

  if (match) return match[1];

  return columnName;
};

export const mapMessageData = (data: GetMessageResDTO[]): IMessage[] =>
  data.map((msg) => {
    const [parsedMetadata, parsedTime] = parseJSONToTableChart(
      msg.timeLevelOptions,
      msg.metaData,
    );

    return {
      ...msg,
      metaData: parsedMetadata,
      timeLevelOptions: parsedTime,
    };
  });
