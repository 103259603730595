import { CKEditor } from '@ckeditor/ckeditor5-react';
import type { EventInfo, Editor } from 'ckeditor5';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Underline,
  Heading,
  Autoformat,
  List,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

interface EditorProps {
  value: string;
  onChange: (event: EventInfo, editor: Editor) => void;
}

export function TextEditor({ value, onChange }: EditorProps) {
  return (
    <CKEditor
      id="custom-editor"
      onChange={onChange}
      data={value}
      editor={ClassicEditor}
      config={{
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
          ],
          shouldNotGroupWhenFull: true,
        },
        plugins: [
          Autoformat,
          Heading,
          Bold,
          Essentials,
          Italic,
          Underline,
          Paragraph,
          List,
        ],
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3',
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4',
            },
          ],
        },
      }}
    />
  );
}
