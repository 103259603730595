import { Button } from 'antd';
import styled from 'styled-components';

export const OutlineButton = styled(Button)`
  border: 1px solid #f5222d;
  color: #f5222d;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 8px;
  height: auto;
`;
