import type { ReactNode } from 'react';
import { Flex, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import ChatBotAvatar from 'assets/icons/chatbot-avatar.png';

import { MessageWrapper } from '../MessageWrapper';

interface TextMessageProps {
  message: ReactNode;
  isResponse: boolean;
  error?: string;
}

export function TextMessage({ message, isResponse, error }: TextMessageProps) {
  const { t } = useTranslation();
  const getFlexDirection = () => {
    if (error) return 'column';
    if (isResponse) return 'row';

    return 'row-reverse';
  };

  return (
    <Flex
      align="end"
      style={{
        flexDirection: getFlexDirection(),
      }}
      gap={error ? 4 : 8}
    >
      {isResponse && <img alt="avatar" src={ChatBotAvatar} />}
      <MessageWrapper
        style={{
          backgroundColor: isResponse ? '#f0f0f0' : '#dbf6fd',
          maxWidth: isResponse ? '90%' : '70%',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        {typeof message === 'string' ? (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: message.replace(/\n/g, '<br />'),
            }}
            style={{ fontSize: 16, lineHeight: '22px' }}
          />
        ) : (
          message
        )}
      </MessageWrapper>
      {error && (
        <Typography.Text type="danger" style={{ fontSize: 12 }}>
          {t('Not Delivered')}
        </Typography.Text>
      )}
    </Flex>
  );
}
