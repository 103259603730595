import type { ModalProps } from 'antd';
import { Flex, Form, Input, notification, Space, Spin } from 'antd';
import { OutlineButton, SolidBtn } from 'components/Buttons';
import { TextButton } from 'components/Buttons/TextButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  getSystemEmail,
  sendTestSystemEmail,
  updateSystemEmail,
} from 'services/email.service';
import type { UpdateEmailReqDTO } from 'types';
import { ConfirmModal } from 'components/Modals';
import { ErrorMessage, EmailResult } from '../EmailResult';

import { CustomForm, CustomModal } from './style';

interface EmailUpdateModalProps extends Pick<ModalProps, 'open'> {
  toggleOpen: (value: boolean) => void;
}

type FieldType = {
  email: string;
  password: string;
};

export function EmailUpdateModal(props: EmailUpdateModalProps) {
  const { open, toggleOpen } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm<UpdateEmailReqDTO>();

  const [shouldShowResult, setShouldShowResult] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>();

  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => () => {
      setShouldShowResult(false);
    },
    [open],
  );

  useEffect(() => {
    if (!open) return;
    getSystemEmail()
      .then((res) =>
        form.setFieldsValue({ email: res.email, password: res.password }),
      )
      .catch(() => {});
  }, [form, open]);

  const handleClose = () => toggleOpen(false);
  const handleCancel = () => toggleOpen(false);

  const handleSendTestEmailBtnClick = () => {
    setIsLoading(true);

    form.submit();

    const { email, password } = form.getFieldsValue();

    sendTestSystemEmail({ email, password })
      .then((res) => {
        setErrorMessage(null);
        setIsLoading(false);

        return res;
      })
      .catch((err) => {
        // TODO: Detect error's type
        console.log('error', err);
        setErrorMessage(ErrorMessage.Credential);
        setIsLoading(false);
      })
      .finally(() => {
        setShouldShowResult(true);
      })
      .catch(() => {});
  };

  const handleConfirmModalOk = () => {
    setIsLoading(true);

    const { email, password } = form.getFieldsValue();

    updateSystemEmail({ email, password })
      .then((res) => {
        toggleConfirmModal(false);
        toggleOpen(false);
        setIsLoading(false);
        notification.success({
          message: t('Update email successfully'),
        });

        return res;
      })
      .catch((err) => {
        // TODO: Detect error's type
        console.log(err);
        setIsLoading(false);

        notification.success({
          message: t('Update email unsuccessfully'),
        });
      });
  };

  return (
    <>
      <CustomModal
        open={open}
        centered
        footer={null}
        title={t('Update system email')}
        width={600}
        onCancel={handleCancel}
        onClose={handleClose}
      >
        <Spin spinning={isLoading}>
          <Flex vertical gap={24}>
            <div className="body">
              <CustomForm
                form={form}
                name="update-email-system"
                layout="vertical"
                preserve
              >
                <CustomForm.Item<FieldType>
                  label={t('Email address')}
                  name="email"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: t('Please input email field!'),
                    },
                    {
                      type: 'email',
                      whitespace: true,
                      message: t('Incorrect format'),
                    },
                  ]}
                >
                  <Input />
                </CustomForm.Item>
                <CustomForm.Item<FieldType>
                  label={t('Password')}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input password field!',
                    },
                  ]}
                >
                  <Input.Password />
                </CustomForm.Item>
              </CustomForm>
              {shouldShowResult && (
                <EmailResult
                  isSuccess={!errorMessage}
                  errorMsg={errorMessage}
                />
              )}
            </div>
            <div className="footer">
              <Flex justify="space-between" align="center">
                <TextButton onClick={handleSendTestEmailBtnClick}>
                  {t('Send test email')}
                </TextButton>
                <Space>
                  <OutlineButton
                    onClick={handleCancel}
                    style={{ marginRight: '12px' }}
                  >
                    {t('Cancel')}
                  </OutlineButton>
                  <SolidBtn
                    onClick={() => toggleConfirmModal(true)}
                    type="primary"
                    style={{ padding: '8px 20px' }}
                  >
                    {t('Save')}
                  </SolidBtn>
                </Space>
              </Flex>
            </div>
          </Flex>
        </Spin>
      </CustomModal>
      <ConfirmModal
        content={
          <Spin spinning={isLoading}>
            {t('Are you sure to save the update?')}
          </Spin>
        }
        handleOk={handleConfirmModalOk}
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={toggleConfirmModal}
        title={t('Update email')}
        okBtn={t('Save')}
      />
    </>
  );
}
