import type {
  GetMessageResDTO,
  Pagination,
  SendMessageReqDTO,
  SendMessageResDTO,
} from 'types';
import { axiosInstance } from '../utils/axios';

export const sendMessage = (id: number, payload: SendMessageReqDTO) =>
  axiosInstance.post<never, SendMessageResDTO>(
    `/api/temporary_chat/${id}/`,
    payload,
  );

export const getMessages = (id: number, page: number = 1) =>
  axiosInstance.get<never, Pagination<GetMessageResDTO>>(
    `/api/conversationchat?conversation_id=${id}&page=${page}`,
  );
