import type { FormProps, SelectProps } from 'antd';
import { Form, Input, message, Select, Spin, Typography } from 'antd';
import { CustomModal } from 'components';
import { useErrors, type ErrorType } from 'hooks/useErrors';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createTerminologyByType,
  getProductLines,
  getTerminologyByType,
  updateTerminologyByType,
} from 'services/terminologyManagement.service';
import type { CreateProductReqDTO, IProductLine } from 'types';
import { getStringFromReactNode } from 'utils';

interface AddEditProductModalProps {
  open: boolean;
  toggleOpen: (value: boolean) => void;
  refetch: () => Promise<void>;
  productId?: number;
  resetSingleState?: () => void;
}

export function AddEditProductModal(props: AddEditProductModalProps) {
  const { open, toggleOpen, refetch, productId, resetSingleState } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateProductReqDTO>();
  const [productLines, setProductLines] = useState<IProductLine[]>([]);
  const { mapToFormErrors } = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const isEdit = !!productId;

  useEffect(() => {
    if (!productId || isLoading || !isEdit || !open) return;
    setIsLoading(true);
    getTerminologyByType<'Product'>('Product', productId)
      .then((res) => {
        setIsLoading(false);

        form.setFieldsValue(res);

        return res;
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [productId, open]);

  const handleOk = () => {
    form.submit();
  };

  const handleFinish: FormProps['onFinish'] = async (
    values: CreateProductReqDTO,
  ) => {
    try {
      setIsLoading(true);

      if (isEdit && productId)
        await updateTerminologyByType('Product', productId, values);
      else await createTerminologyByType('Product', values);

      message.success({
        content: t(
          isEdit
            ? 'Terminology updated successfully'
            : 'Terminology created successfully',
        ),
      });

      setIsLoading(false);
      await refetch();
      toggleOpen(false);
    } catch (error) {
      setIsLoading(false);
      form.setFields(mapToFormErrors(error as ErrorType));
      message.error({
        content: t(
          isEdit
            ? 'Terminology updated unsuccessfully'
            : 'Terminology created unsuccessfully',
        ),
      });
    }
  };

  const handleClose = () => {
    resetSingleState?.();
    toggleOpen(false);
  };

  const getProductlineData = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await getProductLines();

      setProductLines(res);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) getProductlineData();
  }, [getProductlineData, open]);

  return (
    <CustomModal
      {...props}
      title={isEdit ? t('Edit terminology') : t('New terminology')}
      onOk={handleOk}
      onCancel={handleClose}
      onClose={handleClose}
    >
      <Spin spinning={isLoading}>
        <Form<CreateProductReqDTO>
          preserve={false}
          form={form}
          layout="vertical"
          validateTrigger={['onChange']}
          onFinish={handleFinish}
        >
          <Form.Item<CreateProductReqDTO>
            label={t('Product line')}
            required
            name="product_line_id"
            rules={[
              {
                required: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Select
              placeholder={t('Choose product line')}
              options={
                productLines.map((item) => ({
                  label: (
                    <Typography.Text style={{ textWrap: 'wrap' }}>
                      {item.product_line_name} / {item.product_line_name_en}
                    </Typography.Text>
                  ),
                  value: item.id,
                })) as SelectProps['options']
              }
              showSearch
              allowClear
              filterOption={(input, option) =>
                getStringFromReactNode(option?.label)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item<CreateProductReqDTO>
            label={t('Product ID')}
            required
            name="product_id"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input placeholder={t('Enter product ID')} />
          </Form.Item>
        </Form>
      </Spin>
    </CustomModal>
  );
}
