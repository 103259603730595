import type { IConversation, IParam, RenameConversationReqDTO } from 'types';
import { axiosInstance } from '../utils/axios';

export const createConversation = (
  params: Pick<IConversation, 'conversation_name'>,
) => axiosInstance.post<never, IConversation>('/api/conversation', params);

export const getConversations = (params?: IParam) =>
  axiosInstance.get<never, IConversation[]>('/api/conversation', {
    params,
  });

export const getConversation = ({ id }: Pick<IConversation, 'id'>) =>
  axiosInstance.get<never, IConversation[]>(`/api/conversation/${id}`);

export const updateConversation = (
  id: number,
  payload: RenameConversationReqDTO,
) =>
  axiosInstance.put<never, IConversation>(`/api/conversation/${id}/`, payload);

export const deleteConversation = (id: number) =>
  axiosInstance.delete<never, IConversation>(`/api/conversation/${id}`);
