import type { ModalProps } from 'antd';
import { Flex, Modal } from 'antd';
import { OutlineButton, SolidBtn } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #141414;
  }
  .ant-form-item-label label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #141414;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    align-items: center;
  }

  .ant-modal-footer .ant-btn {
    padding: 8px 20px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none; /* Hide the default asterisk */
  }

  .ant-form-item .ant-form-item-label .ant-form-item-required::after {
    content: '*';
    color: #ff4d4f;
    margin-left: 4px;
    display: inline-block;
    visibility: initial !important;
  }
`;

interface CustomModalProps extends ModalProps {}

export function CustomModal(props: CustomModalProps) {
  const {
    onOk,
    cancelText,
    onCancel,
    okText,
    okButtonProps,
    cancelButtonProps,
  } = props;
  const { t } = useTranslation();

  return (
    <StyledModal
      footer={
        <Flex justify="end">
          <OutlineButton
            style={{ marginRight: '12px' }}
            onClick={onCancel}
            {...cancelButtonProps}
          >
            {cancelText ?? t('Cancel')}
          </OutlineButton>
          <SolidBtn
            type="primary"
            htmlType="submit"
            style={{ padding: '8px 20px' }}
            onClick={onOk}
            {...okButtonProps}
          >
            {okText ?? t('Save')}
          </SolidBtn>
        </Flex>
      }
      centered
      destroyOnClose
      {...props}
    />
  );
}
