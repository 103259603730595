import type { ReactNode } from 'react';
import { t } from 'i18next';

import { CustomModal } from './style';
import { OutlineButton, SolidBtn } from '../../Buttons';

export interface ConfirmModalProps {
  isModalOpen: boolean;
  title: string;
  content: ReactNode;
  okBtn?: string;
  cancelBtn?: string;
  width?: number;
  setIsModalOpen: (isModalOpen: boolean) => void;
  handleOk: () => void;
  handleCancel?: () => void;
  destroyOnClose?: boolean;
}

export function ConfirmModal({
  isModalOpen,
  title,
  content,
  okBtn = 'Ok',
  cancelBtn = 'Cancel',
  width = 400,
  setIsModalOpen,
  handleOk,
  handleCancel,
  destroyOnClose = false,
}: ConfirmModalProps) {
  const handleClose = () => {
    if (handleCancel) handleCancel();

    setIsModalOpen(false);
  };

  return (
    <CustomModal
      centered
      footer={null}
      title={title}
      open={isModalOpen}
      width={width}
      onCancel={handleClose}
      destroyOnClose={destroyOnClose}
    >
      <p className="content">{content}</p>
      <div className="footer">
        <OutlineButton style={{ marginRight: '12px' }} onClick={handleClose}>
          {t(cancelBtn)}
        </OutlineButton>
        <SolidBtn
          type="primary"
          style={{ padding: '8px 20px' }}
          onClick={() => handleOk()}
        >
          {t(okBtn)}
        </SolidBtn>
      </div>
    </CustomModal>
  );
}
