import type { IParam, IUser, LoginResDTO, Pagination } from 'types';
import { axiosInstance } from '../utils/axios';

export const getUserManagements = (params?: IParam) =>
  axiosInstance.get<never, Pagination<IUser>>('/api/user', {
    params,
  });

export const createUser = (
  params: Pick<
    IUser,
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'employee_id'
    | 'user_role'
    | 'language'
  >,
) => axiosInstance.post<never, IUser>('/api/user', params);

export const updateUser = (
  params: Pick<
    IUser,
    'id' | 'first_name' | 'last_name' | 'email' | 'employee_id' | 'user_role'
  >,
) => axiosInstance.put<never, IUser>(`/api/user/${params.id}/`, params);

export const deleteUser = (ids: string[]) =>
  axiosInstance.delete<never, IUser>('/api/user/delete/', {
    data: { ids },
  });

export const resetPassword = (id: string) =>
  axiosInstance.post<never, { reset_password: string }>(
    `/api/user/${id}/resetpassword/`,
  );

export const updateLang = (language: string) =>
  axiosInstance.put<never, Exclude<LoginResDTO, 'refresh' | 'access'>>(
    '/api/userprofile/language',
    {
      language,
    },
  );
