import styled from 'styled-components';

export const TerminologyManagementWrapper = styled.div`
  padding: 24px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-side {
      display: flex;
      align-items: center;
    }
    .title {
      margin-left: 18px;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;
      color: #003a8c;
    }
  }
`;
