import { Table } from 'antd';
import styled from 'styled-components';

export const CustomTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    background-color: #1890ff;
    color: white;
  }

  .ant-table-container table > thead > tr:first-child > th:first-child {
    border-radius: 0 !important;
  }

  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-radius: 0 !important;
  }
`;
