import type { LanguageType } from 'types/common';

export enum UserRole {
  User = 'user',
  Admin = 'admin',
}

export interface IUser {
  id: string;
  employee_id: string;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  language: LanguageType;
  is_superuser: boolean;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  last_login: string;
  user_role: UserRole;
}
