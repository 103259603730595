import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import type { PaginationProps, SelectProps, TablePaginationConfig } from 'antd';
import {
  Button,
  Dropdown,
  Input,
  message,
  Select,
  Spin,
  Typography,
} from 'antd';

import { DeleteModal, OutlineButton, SolidBtn } from 'components';

import { UserManagementTab } from 'pages/UserManagement/components/style';
import { CustomTable } from 'components/CustomTable';
import type { IProcess, IXR, IParam, ILine } from 'types';

import {
  deleteTerminologiesByType,
  getTerminologiesByType,
} from 'services/terminologyManagement.service';
import { xrManagementColumns } from 'pages/TerminologyManagement/configs/columns';
import { useAuthStore, useLangStore } from 'stores';
import { getStringFromReactNode } from 'utils';
import { AddEditXRModal, UploadModal } from '../Modals';
import { duplicatedXRColumns } from '../Modals/UploadModal/columns';

export function XRManagement() {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { lang } = useLangStore();

  const [xrs, setXRs] = useState<IXR[]>([]);
  const [lines, setLines] = useState<ILine[]>([]);
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [clickedRecord, setClickedRecord] = useState<IXR | null>(null);
  const [selectedRecords, setSelectedRecords] = useState<IXR[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isAddEditTermModalOpen, toggleAddEditTermModal] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isConfirmDeleteModalOpen, toggleConfirmDeleteModal] = useState(false);

  const [filter, setFilter] = useState<IParam>({ page: 1 });

  useEffect(() => {
    setFilter({ page: 1 });
    setSelectedRowKeys([]);
  }, []);

  const getData = useCallback(async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);

      const sanitizedFilter: IParam = {
        ...filter,
        search: filter?.search?.trim(),
      };
      const result = await getTerminologiesByType<'XR'>('XR', sanitizedFilter);

      setXRs(result.results);
      setTotal(result.count);
      setSelectedRowKeys([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [filter, isLoading]);

  const getLineData = useCallback(async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const res = await getTerminologiesByType<'Line'>('Line', {});

      setLines(res.results);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [isLoading]);

  const getProcessData = useCallback(async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const res = await getTerminologiesByType<'Process'>('Process', {});

      setProcesses(res.results);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    getData();
    getLineData();
    getProcessData();
  }, [filter]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (curSelectedRowKeys: React.Key[], selectedRows: IXR[]) => {
      setSelectedRowKeys(curSelectedRowKeys);
      setSelectedRecords(selectedRows);
    },
    selectedRowKeys,
  };

  const handleChangePage = (pagination: TablePaginationConfig) => {
    setFilter({
      ...filter,
      page: pagination.current ?? 1,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchText(value);
    setFilter({
      ...filter,
      search: value,
      page: 1,
    });
  };

  const handleSelectLine: SelectProps['onChange'] = (value) => {
    setFilter({
      ...filter,
      line_id: value,
      page: 1,
    });
  };

  const handleSelectProcess: SelectProps['onChange'] = (value) => {
    setFilter({
      ...filter,
      process_equipment_id: value,
      page: 1,
    });
  };

  const handleItemRender: PaginationProps['itemRender'] = (
    _current,
    type,
    originalElement,
  ) => {
    if (type === 'prev') {
      return (
        <Button
          shape="circle"
          icon={<LeftOutlined style={{ fontSize: '12px' }} />}
        />
      );
    }

    if (type === 'next') {
      return (
        <Button
          shape="circle"
          icon={<RightOutlined style={{ fontSize: '12px' }} />}
        />
      );
    }

    return originalElement;
  };

  const handleEdit = (record: IXR) => {
    setClickedRecord(record);
    toggleAddEditTermModal(true);
  };

  const handleDelete = (record: IXR) => {
    setClickedRecord(record);
    toggleConfirmDeleteModal(true);
  };

  const handleDeleteOk = async (ids: number[], recordName: string) => {
    const isBulkDeletion = ids.length > 1;

    if (!ids.length) return;

    try {
      setIsLoading(true);
      setIsLoading(false);

      await deleteTerminologiesByType('XR', ids);
      await getData();

      message.success({
        content: isBulkDeletion
          ? t('{{resource}}(s) deleted successfully', {
              resource: t('XR'),
            })
          : t('{{resource}} {{name}} deleted successfully', {
              resource: t('XR'),
              name: recordName,
            }),
      });
    } catch (error) {
      setIsLoading(false);

      message.error({
        content: isBulkDeletion
          ? t('{{resource}}(s) deleted unsuccessfully', {
              resource: t('XR'),
            })
          : t('{{resource}} {{name}} deleted unsuccessfully', {
              resource: t('XR'),
              name: recordName,
            }),
      });
    }
  };

  return (
    <UserManagementTab>
      <Spin spinning={isLoading} fullscreen />
      <div className="header">
        <div className="left-side">
          <Input
            size="large"
            placeholder={t('Search')}
            prefix={<SearchOutlined />}
            style={{ width: '200px', marginRight: '12px' }}
            onChange={handleInputChange}
            value={searchText}
          />
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              getStringFromReactNode(option?.label)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            placeholder={t('Line')}
            size="large"
            onChange={handleSelectLine}
            style={{ minWidth: '180px', marginRight: '12px' }}
            options={
              lines.map((item) => ({
                label: (
                  <Typography.Text style={{ textWrap: 'wrap' }}>
                    {item.line_name} / {item.line_name_en}
                  </Typography.Text>
                ),
                value: item.id,
              })) as SelectProps['options']
            }
          />
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              getStringFromReactNode(option?.label)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            placeholder={t('Process')}
            size="large"
            onChange={handleSelectProcess}
            style={{ minWidth: '180px', marginRight: '12px' }}
            options={
              processes.map((item) => ({
                label: (
                  <Typography.Text style={{ textWrap: 'wrap' }}>
                    {item.process_equipment_name} /{' '}
                    {item.process_equipment_name_en}
                  </Typography.Text>
                ),
                value: item.id,
              })) as SelectProps['options']
            }
          />
          <p
            style={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              textAlign: 'left',
              color: '#595959',
              whiteSpace: 'nowrap',
            }}
          >
            {t('Total')}: {total} item(s)
          </p>
        </div>

        <div className="right-side">
          {user?.is_superuser && (
            <OutlineButton
              style={{ marginRight: '12px' }}
              onClick={() => toggleConfirmDeleteModal(true)}
              disabled={!selectedRowKeys.length}
            >
              {t('Delete')}
            </OutlineButton>
          )}
          <Dropdown
            menu={{
              items: [
                {
                  key: '1',
                  label: t('Add manual'),
                  onClick: () => toggleAddEditTermModal(true),
                },
                {
                  key: '2',
                  label: t('Upload file excel'),
                  onClick: () => toggleUploadModal(true),
                },
              ],
            }}
            trigger={['click']}
          >
            <SolidBtn type="primary" style={{ padding: '8px 20px' }}>
              + {t('New X-R')}
            </SolidBtn>
          </Dropdown>
        </div>
      </div>
      <CustomTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="id"
        className="custom-table"
        columns={xrManagementColumns(
          filter,
          t,
          handleEdit,
          handleDelete,
          user?.is_superuser ?? false,
          lang,
        )}
        dataSource={xrs}
        onChange={handleChangePage}
        pagination={{
          position: ['bottomCenter'],
          total,
          pageSize: 10,
          itemRender: handleItemRender,
        }}
      />
      <DeleteModal<IXR>
        open={isConfirmDeleteModalOpen}
        toggleOpen={toggleConfirmDeleteModal}
        refetch={getData}
        resourceName={t('XR')}
        selectedRecords={clickedRecord ? [clickedRecord] : selectedRecords}
        nameField={lang === 'vn' ? 'xr_name' : 'xr_name_en'}
        onDelete={handleDeleteOk}
        resetSingleState={() => setClickedRecord(null)}
      />
      <AddEditXRModal
        open={isAddEditTermModalOpen}
        toggleOpen={() => {
          toggleAddEditTermModal(false);
          setSelectedRecords([]);
        }}
        refetch={getData}
        xrId={clickedRecord?.id}
        resetSingleState={() => setClickedRecord(null)}
      />

      <UploadModal
        toggleOpen={toggleUploadModal}
        open={isUploadModalOpen}
        accept={['CSV', 'XLS', 'XLSX']}
        termType="XR"
        columns={duplicatedXRColumns(lang, t)}
        refetch={getData}
      />
    </UserManagementTab>
  );
}
