import type { SegmentedProps } from 'antd';
import { Segmented } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface CustomSegmentedProps<T> {
  onChange?: (val: T) => void;
  defaultValue: T;
  options: SegmentedProps<T>['options'];
}

const StyledSegmented = styled(Segmented)`
  .ant-segmented-item,
  .ant-segmented-item-selected,
  .ant-segmented-thumb {
    border-radius: 100px !important;
  }

  .ant-segmented-group {
    gap: 4px;
  }
` satisfies typeof Segmented;

export function CustomSegmented<T>({
  defaultValue,
  options,
  onChange,
}: CustomSegmentedProps<T>) {
  const [option, setOption] = useState<T>(defaultValue);

  useEffect(() => {
    setOption(defaultValue);
  }, [defaultValue]);

  const handleChange: SegmentedProps<T>['onChange'] = (e) => {
    setOption(e);
    onChange?.(e);
  };

  return (
    <StyledSegmented<T>
      options={options}
      style={{
        border: '0.5px solid #D9D9D9',
        borderRadius: '100px',
        width: 'fit-content',
        gap: 8,
      }}
      value={option}
      onChange={handleChange}
      size="small"
    />
  );
}
