import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { MenuRef } from 'antd';
import { Button, Divider, Flex, Spin, Typography } from 'antd';
import Sider from 'antd/es/layout/Sider';
import type { ItemType } from 'antd/es/menu/interface';

import Logo from 'assets/images/logo-authen.png';
import LogoDensoXFpt from 'assets/images/logo-densoxfpt.png';
import { createConversation } from 'services/conversation.service';
import type { IConversation } from 'types';

import { useChatStore } from 'stores/useChatStore';
import MenuItem from './MenuItem';
import { CustomMenu } from './style';
import { generateUniqueConversationName } from '../utils';

interface MenuSideBarProps {
  onConversationClick: (item: IConversation) => void;
  refetch: () => Promise<IConversation[]>;
}

function MenuSideBar({ onConversationClick, refetch }: MenuSideBarProps) {
  const { t } = useTranslation();
  const { conversations, curConversation, setCurConversation } = useChatStore();
  const [isLoading, setIsLoading] = useState(false);

  const conversationRef = useRef<MenuRef>(null);

  const scrollToTop = () => {
    if (conversationRef.current) {
      conversationRef.current.menu?.list.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (conversationRef.current) scrollToTop();
  }, [conversations]);

  const createData = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await createConversation({
        conversation_name: generateUniqueConversationName(conversations),
      });
      setIsLoading(false);

      const updatedConversations = await refetch();

      setCurConversation(updatedConversations[0]);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleRefetchAfterDelete = async (deletedId?: number) => {
    const newConversations = await refetch();

    if (deletedId === curConversation?.id && newConversations.length > 0)
      setCurConversation(newConversations[0]);
  };

  return (
    <Sider
      width="300px"
      style={{
        backgroundColor: '#E8F5FA',
        padding: 10,
        borderRadius: 20,
        height: '100%',
        border: '1px solid #4AACD3',
        zIndex: 50,
      }}
    >
      <Spin spinning={isLoading} fullscreen />
      <Flex
        gap="middle"
        vertical
        align="center"
        style={{ height: '100%', padding: '16px 12px' }}
        justify="space-between"
      >
        <Flex vertical align="center">
          <Link to="/">
            <img src={Logo} alt="" width={115} style={{ margin: 0 }} />
          </Link>
          <Typography.Text style={{ fontWeight: 500, fontSize: 12 }}>
            DensoGPT
          </Typography.Text>
        </Flex>

        <Divider style={{ margin: 0 }} />

        <CustomMenu
          ref={conversationRef}
          mode="inline"
          style={{
            flexGrow: 1,
            backgroundColor: 'inherit',
            borderInlineEnd: 'none',
            overflow: 'auto',
          }}
          selectedKeys={[`${curConversation?.id ?? 0}`]}
          items={
            conversations.map((item) => ({
              key: item.id,
              label: (
                <MenuItem
                  item={item}
                  refetch={handleRefetchAfterDelete}
                  onConversationClick={() => onConversationClick(item)}
                />
              ),
              style: {
                paddingInline: 0,
                marginInline: 0,
                height: 52,
              },
            })) as ItemType[]
          }
        />

        <Flex
          style={{
            borderRadius: 24,
            border:
              '0.5px solid linear-gradient(87.64deg, #C2EDFF 1.1%, #DEF5FF 100.67%)',
            boxShadow: '0px 2px 12px -32px #0060891A',
            backgroundColor: '#FFFFFF80',
            padding: 20,
            width: '100%',
          }}
          align="center"
          vertical
          gap={24}
        >
          <Flex vertical align="center" style={{ textAlign: 'center' }}>
            <Typography.Title level={5} style={{ fontWeight: 700 }}>
              {t('Lets start!')}
            </Typography.Title>
            <Typography.Text>
              {t('Everything now in your control with DensoGPT')}
            </Typography.Text>
          </Flex>

          <Button
            style={{
              fontSize: 16,
              fontWeight: 500,
              textWrap: 'wrap',
              height: 'auto',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
            type="primary"
            block
            onClick={createData}
          >
            {t('+ New chat')}
          </Button>
        </Flex>
        <img src={LogoDensoXFpt} alt="" width={86.5} />
      </Flex>
    </Sider>
  );
}

export default MenuSideBar;
