import type { ModalProps } from 'antd';
import { Flex, Form, Input, notification, Select, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { OutlineButton, SolidBtn, TextButton } from 'components/Buttons';
import type { UpdateUserReqDTO } from 'types/dto/requests/user';
import {
  getUserProfile,
  updateUserProfile,
} from 'services/userProfile.service';
import { useAuthStore } from 'stores';
import type { UserRole } from 'types';
import { CustomForm, CustomModal } from '../style';
import { ResetPasswordModal } from '../ResetPasswordModal';

interface UserUpdateModalProps extends Pick<ModalProps, 'open'> {
  toggleOpen: (value: boolean) => void;
}

type UpdateUserForm = UpdateUserReqDTO & {
  email: string;
  employee_id: string;
  user_role: UserRole | 'superadmin';
};

export function UserUpdateModal(props: UserUpdateModalProps) {
  const { open, toggleOpen } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm<UpdateUserForm>();
  const { user } = useAuthStore();

  const [isResetPwdModalOpen, toggleResetPwdModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return;

    getUserProfile()
      .then((res) => {
        const userInformation: UpdateUserForm = {
          ...res,
          user_role: res.is_superuser ? 'superadmin' : res.user_role,
        };

        form.setFieldsValue(userInformation);

        return res;
      })
      .catch(() => {});
  }, [form, open]);

  const handleClose = () => toggleOpen(false);
  const handleCancel = () => toggleOpen(false);

  const handleResetPwd = () => toggleResetPwdModal(true);

  const handleOk = () => {
    setIsLoading(true);

    const { first_name, last_name } = form.getFieldsValue();

    updateUserProfile({ first_name, last_name })
      .then((res) => {
        setIsLoading(false);
        notification.success({
          message: t('Update user successfully'),
        });

        toggleOpen(false);

        return res;
      })
      .catch(() => {
        setIsLoading(false);
        notification.error({
          message: t('Update user unsuccessfully'),
        });
      });
  };

  return (
    <>
      <CustomModal
        open={open}
        centered
        footer={null}
        title={t('Update personal information')}
        width={600}
        onCancel={handleCancel}
        onClose={handleClose}
      >
        <Spin spinning={isLoading}>
          <Flex vertical gap={24}>
            <div className="body">
              <CustomForm
                form={form}
                name="update-user-information"
                layout="vertical"
                preserve
                initialValues={
                  {
                    first_name: user?.first_name ?? 'cc',
                    last_name: user?.last_name,
                  } as UpdateUserForm
                }
              >
                <CustomForm.Item<UpdateUserForm>
                  label={t('First name')}
                  name="first_name"
                >
                  <Input maxLength={50} />
                </CustomForm.Item>
                <CustomForm.Item<UpdateUserForm>
                  label={t('Last name')}
                  name="last_name"
                >
                  <Input maxLength={50} />
                </CustomForm.Item>
                <CustomForm.Item<UpdateUserForm>
                  label={t('Email')}
                  name="email"
                >
                  <Input disabled value={user?.email} />
                </CustomForm.Item>
                <CustomForm.Item<UpdateUserForm>
                  label={t('Employee ID')}
                  name="employee_id"
                >
                  <Input disabled />
                </CustomForm.Item>
                <CustomForm.Item<UpdateUserForm>
                  label={t('Role')}
                  name="user_role"
                >
                  <Select
                    disabled
                    options={[
                      { label: 'Admin', value: 'admin' },
                      { label: 'User', value: 'user' },
                      { label: 'Super Admin', value: 'superadmin' },
                    ]}
                  />
                </CustomForm.Item>
              </CustomForm>
            </div>
            <div className="footer">
              <Flex justify="space-between" align="center">
                <TextButton onClick={handleResetPwd}>
                  {t('Reset password')}
                </TextButton>
                <Space>
                  <OutlineButton
                    onClick={handleCancel}
                    style={{ marginRight: '12px' }}
                  >
                    {t('Cancel')}
                  </OutlineButton>
                  <SolidBtn
                    onClick={handleOk}
                    type="primary"
                    style={{ padding: '8px 20px' }}
                  >
                    {t('Save')}
                  </SolidBtn>
                </Space>
              </Flex>
            </div>
          </Flex>
        </Spin>
      </CustomModal>
      <ResetPasswordModal
        toggleOpen={toggleResetPwdModal}
        open={isResetPwdModalOpen}
      />
    </>
  );
}
