import type { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: '#f5222d',
    fontFamily: 'Inter',
  },
  components: {
    Input: {
      colorPrimary: '#1677FF',
      colorPrimaryActive: '#1677FF',
      colorPrimaryHover: '#1677FF',
    },
    Select: {
      colorPrimary: '#1677FF',
      colorPrimaryActive: '#1677FF',
      colorPrimaryHover: '#1677FF',
    },
    Menu: {
      itemHoverBg: '#9ed8f0',
      itemActiveBg: '#9ed8f0',
      itemSelectedBg: '#9ed8f0',
      itemBorderRadius: 10,
      itemColor: '#141414',
      itemSelectedColor: '#141414',
    },
    Segmented: {
      itemSelectedBg: '#FFCCC7',
      itemSelectedColor: '#F5222D',
      trackBg: '#FAFAFA',
      trackPadding: '6px 16px',
    },
    Table: {
      headerSortHoverBg: '#1677FF',
      headerSortActiveBg: '#1677FF',
    },
  },
};
