import { Form, Modal } from 'antd';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 12px;
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #262626;
  }
`;

export const CustomForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 12px;
    font-weight: 500;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none; /* Hide the default asterisk */
  }

  .ant-form-item .ant-form-item-label .ant-form-item-required::after {
    content: '*';
    color: #ff4d4f;
    margin-left: 4px;
    display: inline-block;
    visibility: initial !important;
  }
`;
