import type { PropsWithChildren } from 'react';
import { Layout } from 'antd';

function ChatLayout({ children }: PropsWithChildren) {
  return (
    <Layout
      style={{
        height: '100vh',
        padding: 20,
        backgroundColor: 'inherit',
        gap: 50,
      }}
    >
      {children}
    </Layout>
  );
}

export default ChatLayout;
