import { Result } from 'antd';
import styled from 'styled-components';

export const CustomResult = styled(Result)`
  padding: 24px 64px;

  .ant-result-title {
    margin-block: 0px;
  }
  .ant-result-icon {
    margin-bottom: 10px;
  }
`;
