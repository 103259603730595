type MeasurementUnitType =
  | '%'
  | 'pcs'
  | 'min'
  | 'batch'
  | 's'
  | 'count'
  | 'day_time'
  | 'change_point'
  | null;

export const MeasurementMap: Record<string, MeasurementUnitType> =
  Object.freeze({
    product_amount: 'pcs',
    product_amount_percentage: '%',
    scrap_amount: 'pcs',
    line_name: 'pcs',
    oee_percentage: '%',
    planned_down_loss_percentage: '%',
    performance_minutes: 'min',
    performance_percentage: '%',
    performance_batches: 'batch',
    total_scrap_rate: '%',
    computed_total_scrap_rate: '%',
    total_scrap_amount: 'pcs',
    total_production_amount: 'pcs',
    target_production_amount: 'pcs',
    actual_production_amount: 'pcs',
    loading_time_minutes: 'min',
    net_production_amount: 'pcs',
    target_cycle_time: 's',
    target_percentage: '%',
    oee_percentage_by_date: '%',
    total_of_change_point: 'change_point',
    planned_down_loss_percentage_by_date: '%',
    unplanned_down_loss_percentage: '%',
    unplanned_down_loss_percentage_by_date: '%',
    exclude_loss_percentage: '%',
    other_loss_percentage: '%',
    unknown_percentage: '%',
    target_production_amount_sum: 'pcs',
    actual_production_amount_sum: 'pcs',
    ot_time: 'day_time',
    business_date: 'day_time',
    measurement_count: 'count',
    xbar: null,
    rs: null,
    xbar_avg: null,
    standard_deviation: null,
    cp: null,
    cpk: null,
    is_xr_meet_expect: null,
  });

export const UnitMapFilter = {
  start_of_week: true,
  end_of_week: true,
  business_date: true,
  business_month: true,
  shift_name: true,
};
