import { MeasurementMap, UnitMapFilter } from 'pages/Chat/constants';
import type { IChartContent, IChartType, ITableContent } from 'types';
import {
  defaultChartByColumns,
  priorityDefaultChart,
} from './ChartMessage/constant';

export const is2MeasurementUnits = (content: IChartContent) =>
  content.measurement.length === 2 &&
  MeasurementMap[content.measurement[0]] !==
    MeasurementMap[content.measurement[1]];

export const isChartDrawable = (content: IChartContent): boolean =>
  content.data.length > 1 &&
  content.entity.length > 0 &&
  content.label.length > 1 &&
  content.measurement.length > 0;

export const isTableDrawable = (content: ITableContent): boolean =>
  content.data &&
  content.data.body.length > 0 &&
  content.data.header.length > 0;

export const isPieDrawable = (measurements: string[]) =>
  measurements.some((item) => MeasurementMap[item] === '%');

export const isLineDrawable = (data: string[] | number[]) => data.length > 1;

export const isParetoDrawable = (content: IChartContent) =>
  is2MeasurementUnits(content) && content.data[0].length > 1;

export const determineDefaultChartType = (
  content: IChartContent,
): IChartType | null => {
  let defaultType: null | IChartType = null;

  content.label.forEach((item) => {
    const chartType = defaultChartByColumns[item];

    if (!chartType) return;

    if (!defaultType) {
      defaultType = chartType;

      return;
    }

    if (
      priorityDefaultChart.findIndex((i) => i === chartType) <
      priorityDefaultChart.findIndex((i) => i === defaultType)
    )
      defaultType = chartType;
  });

  return defaultType;
};

export const determineChartTypes = (content: IChartContent) => {
  const chartTypes: IChartType[] = [];

  if (isChartDrawable(content)) {
    chartTypes.push('Table');
    chartTypes.push('Bar');

    if (isParetoDrawable(content)) chartTypes.push('Pareto');

    // if (isPieDrawable(content.measurement)) chartTypes.push('Pie');
    if (isLineDrawable(content.data[0])) chartTypes.push('Line');
  } else {
    chartTypes.push('Table');
  }

  return chartTypes;
};

const getXUnit = (data: string[]) => {
  if (data.length === 1) return data[0];

  const unit = data.find((item) => !UnitMapFilter[item as never]);

  return unit ?? data[0];
};

function paginateArray<T>(array: T[], pageIndex: number, itemsPerPage: number) {
  const startIndex = (pageIndex - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return array.slice(startIndex, endIndex);
}

function paginate2DArray<T>(
  array: T[][],
  pageIndex: number,
  itemsPerPage: number,
): T[][] {
  const clonedArray = JSON.parse(JSON.stringify(array)) as T[][];

  return clonedArray.map((row) => paginateArray(row, pageIndex, itemsPerPage));
}

export const mapResponseToChartData = (
  content?: IChartContent,
  pageIndex = 0,
  pageSize = 10,
) => {
  if (!content || !isChartDrawable(content)) {
    return {
      xCat: [[], []],
      data: [[], []],
      line: [],
      min: 0,
      max: 0,
      yUnit: '',
      xUnit: '',
      pieData: [],
    };
  }

  let pieIndex = -1;
  const dateIndex = content.label.indexOf('date');

  if (dateIndex !== -1 && content?.entity?.length > 1) {
    content?.data?.splice(dateIndex, 1);
    content?.label?.splice(dateIndex, 1);
  }

  const entityIndexes = content?.entity
    ?.map((entity) => content?.label.indexOf(entity))
    .filter((idx) => idx !== -1);

  const measurementIndexes = content?.measurement
    .map((measurement) => {
      // Find percentage measurement
      if (/\b\w*_percentage\b/.test(measurement))
        pieIndex = content?.label.indexOf(measurement);

      return content?.label.indexOf(measurement);
    })
    .filter((idx) => idx !== -1);

  const xCat = entityIndexes.map((idx) => content.data[idx] as string[]) ?? [];

  const dataArray =
    measurementIndexes.map((idx) => content?.data[idx] as number[]) ?? [];

  const xUnit = getXUnit(content.entity);
  const yUnit = content.label[measurementIndexes[0]];
  const yUnit2 = is2MeasurementUnits(content)
    ? content.label[measurementIndexes[1]]
    : undefined;

  const lineData = (
    content?.line.length > 0
      ? Array(dataArray[0].length).fill(content?.line[0])
      : []
  ) as number[];

  const pieData = pieIndex < 0 ? [] : (content.data[pieIndex] as number[]);

  const paginatedData = paginate2DArray(dataArray, pageIndex, pageSize);
  const paginatedXCat = paginate2DArray(xCat, pageIndex, pageSize);
  const paginatedPieData = paginateArray(pieData, pageIndex, pageSize);

  const flatArray = paginatedData.reduce((acc, val) => [...acc, ...val], []);

  return {
    xCat: paginatedXCat,
    data: paginatedData,
    line: lineData,
    max: Math.max(...(flatArray as number[])),
    min: Math.min(...(flatArray as number[])),
    xUnit,
    yUnit,
    yUnit2,
    pieData: paginatedPieData,
  };
};
