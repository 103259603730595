import type { IParam, ISystemEmail, IUser } from 'types';
import { axiosInstance } from '../utils/axios';

export const getSystemEmail = (params?: IParam) =>
  axiosInstance.get<never, ISystemEmail>('/api/systememail', {
    params,
  });

export const updateSystemEmail = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => axiosInstance.put<never, IUser>('/api/systememail', { email, password });

export const sendTestSystemEmail = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) =>
  axiosInstance.post<never, IUser>('/api/systememail/test', {
    email,
    password,
  });
