import styled from 'styled-components';

export const SignUpWrapper = styled.div`
  width: 700px;
  padding: 40px;

  .title {
    font-size: 40px;
    font-weight: 600;
    color: #003a8c;
    margin-bottom: 10px;
    position: relative;
    margin-top: 52px;
    display: inline-block;
    img {
      position: absolute;
      top: -15px;
      right: -30px;
    }
  }
  .sub-title {
    font-size: 20px;
    font-weight: 500;
    color: #003a8c;
    margin-bottom: 36px;
    line-height: 28px;
  }

  .ant-input-affix-wrapper {
    padding: 17px 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .button-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
  }

  .footer {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-top: 36px;
  }
  .ant-input-group-addon {
    background-color: #fff;
  }
  .custom-password-input .ant-input-affix-wrapper {
    border-right: none;
    padding-right: 0;
  }
  .ant-input-outlined:hover + .ant-input-group-addon {
    border-color: #4096ff;
  }
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover
    + .ant-input-group-addon {
    border-color: #ffa39e;
  }
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus,
  .ant-input-outlined.ant-input-status-error:not(
      .ant-input-disabled
    ):focus-within {
    + .ant-input-group-addon {
      border-color: #ff4d4f;
    }
  }
  .ant-input-outlined:focus-within + .ant-input-group-addon {
    border-color: #1677ff;
  }
`;

export const SignUpSuccessfullyWrapper = styled.div`
  width: 500px;
  padding: 40px;
  text-align: center;
  .img-area {
    text-align: center;
    margin-bottom: 10px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #003a8c;
    margin-bottom: 10px;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #262626;
    margin-bottom: 16px;
  }

  .contact {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 36px;

    span {
      color: #096dd9;
      padding-left: 4px;
    }
  }
`;
