import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Flex, type TableColumnsType } from 'antd';
import { IconButton } from 'components';
import type { TFunction } from 'i18next';
import type {
  ILine,
  IParam,
  IProcess,
  IProduct,
  IXR,
  LanguageType,
} from 'types';
import { formatDate } from 'utils/time';

export const processManagementColumns = (
  filter: IParam,
  t: TFunction<'translation', undefined>,
  onEditClick: (ecord: IProcess) => void,
  onDeleteClick: (record: IProcess) => void,
  isSuperAdmin: boolean,
  lang: LanguageType,
): TableColumnsType<IProcess> => [
  {
    title: 'No',
    dataIndex: 'no',
    width: 60,
    render: (_value: string, _record: IProcess, index: number) => {
      const no = ((filter.page || 1) - 1) * 10 + index + 1;

      return (
        <div>
          <span className="pl-2">{no}</span>
        </div>
      );
    },
  },
  {
    title: t('Line group'),
    dataIndex: lang === 'vn' ? 'line_group' : 'line_group_en',
  },
  {
    title: t('Process'),
    dataIndex:
      lang === 'vn' ? 'process_equipment_name' : 'process_equipment_name_en',
  },
  {
    title: t('Created by'),
    dataIndex: 'user',
  },
  {
    title: t('Created time'),
    dataIndex: 'created',
    render: (value: string) => formatDate(new Date(value)),
  },
  {
    width: 400,
    render: (_value: string, record: IProcess) => (
      <Flex justify="flex-end">
        <IconButton
          onClick={() => onEditClick(record)}
          icon={<EditOutlined style={{ fontSize: '20px' }} />}
          style={{ marginRight: '20px' }}
        />
        {isSuperAdmin && (
          <IconButton
            onClick={() => onDeleteClick(record)}
            icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
          />
        )}
      </Flex>
    ),
  },
];

export const lineManagementColumns = (
  filter: IParam,
  t: TFunction<'translation', undefined>,
  onEditClick: (record: ILine) => void,
  onDeleteClick: (record: ILine) => void,
  isSuperAdmin: boolean,
  lang: LanguageType,
): TableColumnsType<ILine> => [
  {
    title: 'No',
    dataIndex: 'no',
    width: 60,
    render: (_value: string, _record: ILine, index: number) => {
      const no = ((filter.page || 1) - 1) * 10 + index + 1;

      return (
        <div>
          <span className="pl-2">{no}</span>
        </div>
      );
    },
  },
  {
    title: t('Group'),
    dataIndex: lang === 'vn' ? 'line_group' : 'line_group_en',
  },
  {
    title: t('Line'),
    dataIndex: lang === 'vn' ? 'line_name' : 'line_name_en',
  },
  {
    title: t('Created by'),
    dataIndex: 'user',
  },
  {
    title: t('Created time'),
    dataIndex: 'created',
    render: (value: string) => formatDate(new Date(value)),
  },
  {
    width: 400,
    render: (_value: string, record: ILine) => (
      <Flex justify="flex-end">
        <IconButton
          onClick={() => onEditClick(record)}
          icon={<EditOutlined style={{ fontSize: '20px' }} />}
          style={{ marginRight: '20px' }}
        />
        {isSuperAdmin && (
          <IconButton
            onClick={() => onDeleteClick(record)}
            icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
          />
        )}
      </Flex>
    ),
  },
];

export const productManagementColumns = (
  filter: IParam,
  t: TFunction<'translation', undefined>,
  onEditClick: (record: IProduct) => void,
  onDeleteClick: (record: IProduct) => void,
  isSuperAdmin: boolean,
  lang: LanguageType,
): TableColumnsType<IProduct> => [
  {
    title: 'No',
    dataIndex: 'no',
    width: 60,
    render: (_value: string, _record: IProduct, index: number) => {
      const no = ((filter.page || 1) - 1) * 10 + index + 1;

      return (
        <div>
          <span className="pl-2">{no}</span>
        </div>
      );
    },
  },
  {
    title: t('Product line'),
    dataIndex: lang === 'vn' ? 'product_line' : 'product_line_en',
  },
  {
    title: t('Product ID'),
    dataIndex: 'product_id',
  },
  {
    title: t('Created by'),
    dataIndex: 'user',
  },
  {
    title: t('Created time'),
    dataIndex: 'created',
    render: (value: string) => formatDate(new Date(value)),
  },
  {
    width: 400,
    render: (_value: string, record: IProduct) => (
      <Flex justify="flex-end">
        <IconButton
          onClick={() => onEditClick(record)}
          icon={<EditOutlined style={{ fontSize: '20px' }} />}
          style={{ marginRight: '20px' }}
        />
        {isSuperAdmin && (
          <IconButton
            onClick={() => onDeleteClick(record)}
            icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
          />
        )}
      </Flex>
    ),
  },
];

export const xrManagementColumns = (
  filter: IParam,
  t: TFunction<'translation', undefined>,
  onEditClick: (record: IXR) => void,
  onDeleteClick: (record: IXR) => void,
  isSuperAdmin: boolean,
  lang: LanguageType,
): TableColumnsType<IXR> => [
  {
    title: 'No',
    dataIndex: 'no',
    width: 60,
    render: (_value: string, _record: IXR, index: number) => {
      const no = ((filter.page || 1) - 1) * 10 + index + 1;

      return (
        <div>
          <span className="pl-2">{no}</span>
        </div>
      );
    },
  },
  {
    title: t('Control chart name'),
    dataIndex: lang === 'vn' ? 'xr_name' : 'xr_name_en',
  },
  {
    title: t('Process'),
    dataIndex: lang === 'vn' ? 'process_equipment' : 'process_equipment_en',
  },
  {
    title: t('Line'),
    dataIndex: lang === 'vn' ? 'line' : 'line_en',
  },
  {
    title: t('Created by'),
    dataIndex: 'user',
  },
  {
    title: t('Created time'),
    dataIndex: 'created',
    render: (value: string) => formatDate(new Date(value)),
  },
  {
    width: 400,
    render: (_value: string, record: IXR) => (
      <Flex justify="flex-end">
        <IconButton
          onClick={() => onEditClick(record)}
          icon={<EditOutlined style={{ fontSize: '20px' }} />}
          style={{ marginRight: '20px' }}
        />
        {isSuperAdmin && (
          <IconButton
            onClick={() => onDeleteClick(record)}
            icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
          />
        )}
      </Flex>
    ),
  },
];
