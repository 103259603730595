import type { TableProps } from 'antd';
import { Table, Typography, Flex } from 'antd';

import EmptyData from 'assets/icons/empty-data.png';
import { useTranslation } from 'react-i18next';

interface CustomTableProps extends TableProps {}

export function CustomTable(props: CustomTableProps) {
  const { locale, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Table
      {...rest}
      locale={{
        ...locale,
        emptyText: (
          <Flex
            vertical
            justify="center"
            align="center"
            style={{ padding: '10rem 0' }}
            gap={16}
          >
            <img src={EmptyData} alt="empty-data" />
            <Typography.Text style={{ fontWeight: 'bold' }}>
              {t('No matching item')}
            </Typography.Text>
          </Flex>
        ),
      }}
    />
  );
}
