import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { FormProps } from 'antd';
import { Form, Spin, Tooltip } from 'antd';

import { useState } from 'react';
import { AuthenInput, AuthenLink } from 'layouts/AuthenLayout/style';
import { resetPassword } from 'services/auth.service';
import { SolidBtn } from 'components/Buttons';
import { CreateNewPasswordWrapper } from '../style';
import { ScreenState } from '../types';

type FieldType = {
  password?: string;
  confirmPassword?: string;
};

interface Props {
  setScreen: (screen: ScreenState) => void;
}
export function CreateNewPassword({ setScreen }: Props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const otp = searchParams.get('otp');

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (!email || !otp || !values.password) return;
    try {
      setIsLoading(true);

      await resetPassword({
        email,
        otp,
        new_password: values.password,
      });
      setIsLoading(false);

      setScreen(ScreenState.SuccessChangePassword);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo,
  ) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <CreateNewPasswordWrapper>
      <Spin spinning={isLoading}>
        <p className="title">{t('New password')}</p>
        <p className="sub-title">{t('Please enter your new password')}</p>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            name="password"
            rules={[
              {
                required: true,
                message: t('Please input password field!'),
              },
              {
                pattern: passwordRegex,
                message: t('Invalid password field'),
              },
            ]}
          >
            <AuthenInput.Password
              className="custom-password-input"
              placeholder={`${t('Enter password')} *`}
              addonAfter={
                <Tooltip
                  color="#fff"
                  key="#fff"
                  overlayInnerStyle={{
                    backgroundColor: '#fff',
                    color: '#262626',
                    borderRadius: '6px',
                  }}
                  placement="right"
                  title={
                    <div style={{ padding: '16px' }}>
                      <ul
                        style={{
                          listStyleType: 'disc',
                          paddingLeft: '20px',
                        }}
                      >
                        <li>
                          {t('Password must be at least 8 characters long.')}
                        </li>
                        <li>
                          {t(
                            'It must include at least one uppercase letter, one lowercase letter, and one number.',
                          )}
                        </li>
                      </ul>
                    </div>
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>

          <Form.Item<FieldType>
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('Please retype your password!'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value)
                    return Promise.resolve();

                  return Promise.reject(
                    new Error(
                      t('The new password that you entered do not match!'),
                    ),
                  );
                },
              }),
            ]}
          >
            <AuthenInput.Password placeholder={`${t('Retype password')} *`} />
          </Form.Item>
          <div className="button-area">
            <AuthenLink to="/login">{t('Back to Login')}</AuthenLink>
            <SolidBtn type="primary" htmlType="submit">
              {t('Confirm')}
            </SolidBtn>
          </div>
        </Form>
      </Spin>
    </CreateNewPasswordWrapper>
  );
}
