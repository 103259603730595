import { useState } from 'react';

import AuthenLayout from 'layouts/AuthenLayout';

import SignupForm from './components/SignupForm';
import SignUpSuccessfully from './components/SignUpSuccessfully';

function SignUp() {
  const [isSignupForm, setIsSignupForm] = useState(true);

  return (
    <AuthenLayout>
      {isSignupForm ? (
        <SignupForm setIsSignupForm={setIsSignupForm} />
      ) : (
        <SignUpSuccessfully />
      )}
    </AuthenLayout>
  );
}

export default SignUp;
