import { useTranslation } from 'react-i18next';
import { Flex, Form, List, notification, Space, Typography } from 'antd';
import { OutlineButton, SolidBtn, TextButton } from 'components/Buttons';
import { CustomSegmented } from 'components/CustomSegmented';
import { useEffect, useState } from 'react';
import { capitalize } from 'utils';
import type { IGuideline, LanguageType, SaveGuidelineReqDTO } from 'types';
import {
  cancelDraftGuideline,
  getDraftGuidelines,
  getPublishedGuidelines,
  saveDraftGuideline,
  savePublishedGuideline,
} from 'services/guideline.service';
import { useLangStore } from 'stores';
import { GuideItem } from './GuideItem';
import { CustomModal } from '../CustomModal';
import { mergeGuidelines, validateGuidelines } from './utils';

interface GuideModalProps {
  open: boolean;
  toggleOpen: (value: boolean) => void;
  isEdit?: boolean;
}

export function GuideModal({
  open,
  toggleOpen,
  isEdit = false,
}: GuideModalProps) {
  const { t } = useTranslation();
  const { lang } = useLangStore();
  const [form] = Form.useForm();

  const [isLoading, toggleLoading] = useState(false);
  const [confirmDraftModalOpen, toggleConfirmDraftModal] = useState(false);
  const [confirmPublishModalOpen, toggleConfirmPublishModal] = useState(false);

  const [guideLang, setGuideLang] = useState<LanguageType>(lang);

  const [guidelinesEn, setGuidelinesEn] = useState<IGuideline[]>([]);
  const [guidelinesVi, setGuidelinesVi] = useState<IGuideline[]>([]);

  const [dataSource, setDataSource] = useState<IGuideline[]>([]);

  useEffect(() => {
    const targetDataSource = guideLang === 'en' ? guidelinesEn : guidelinesVi;

    setDataSource(targetDataSource);
  }, [guideLang, guidelinesEn, guidelinesVi]);

  const [isFormInvalid, toggleFormInvalid] = useState(false);

  useEffect(() => {
    setGuideLang(lang);
  }, [lang]);

  useEffect(() => {
    const isValid = validateGuidelines([...guidelinesEn, ...guidelinesVi]);

    toggleFormInvalid(!isValid);
  }, [guidelinesEn, guidelinesVi]);

  useEffect(() => {
    if (!open) {
      toggleConfirmDraftModal(false);
      toggleConfirmPublishModal(false);
      setGuideLang(lang);

      return;
    }

    toggleLoading(true);

    let request = null;

    if (isEdit) request = getDraftGuidelines();
    else request = getPublishedGuidelines();

    request
      .then((res) => {
        const enGuidelines = res.filter((item) => item.language === 'en');
        const viGuidelines = res.filter((item) => item.language === 'vn');

        setGuidelinesEn(enGuidelines);
        setGuidelinesVi(viGuidelines);

        toggleLoading(false);

        return res;
      })
      .catch((err) => {
        console.log(err);
        toggleLoading(false);
      });
  }, [open]);

  const handleClose = () => {
    toggleOpen(false);
  };

  const handleAddBlock = () => {
    const newBlockNumber =
      guideLang === 'en' ? guidelinesEn.length + 1 : guidelinesVi.length + 1;
    const newGuideline: IGuideline = {
      id: '',
      block_number: newBlockNumber,
      content: '',
      language: guideLang,
    };

    if (guideLang === 'en') setGuidelinesEn((prev) => [...prev, newGuideline]);
    else setGuidelinesVi((prev) => [...prev, newGuideline]);
  };

  const handlePublish = () => {
    toggleLoading(true);

    const totalGuidelines = mergeGuidelines(guidelinesEn, guidelinesVi);

    const submitGuilines: SaveGuidelineReqDTO['guidelines'] =
      totalGuidelines.map((item) => ({
        block_number: item.block_number,
        content: item.content,
        language: item.language,
        id: item.id,
      }));

    savePublishedGuideline({ guidelines: submitGuilines })
      .then((res) => {
        toggleOpen(false);
        toggleLoading(false);

        notification.success({ message: t('Publish guideline successfully') });

        return res;
      })
      .catch((err) => {
        console.log(err);

        notification.error({
          message: t('Publish guideline unsuccessfully'),
        });

        toggleLoading(false);
      });
  };

  const handleDraft = () => {
    toggleLoading(true);

    const totalGuidelines = mergeGuidelines(guidelinesEn, guidelinesVi);

    const submitGuilines: SaveGuidelineReqDTO['guidelines'] =
      totalGuidelines.map((item) => ({
        block_number: item.block_number,
        content: item.content,
        language: item.language,
      }));

    saveDraftGuideline({ guidelines: submitGuilines })
      .then((res) => {
        toggleOpen(false);
        toggleLoading(false);
        notification.success({
          message: t('Save guideline as draft successfully'),
        });

        return res;
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: t('Save guideline as draft unsuccessfully'),
        });
        toggleLoading(false);
      });
  };

  const getFooter = () => {
    if (isEdit) {
      return (
        <Flex justify="space-between" align="center">
          <TextButton onClick={handleAddBlock}>{t('Add new block')}</TextButton>
          <Space>
            <OutlineButton
              onClick={() => toggleConfirmDraftModal(true)}
              style={{ marginRight: '12px' }}
            >
              {t('Cancel')}
            </OutlineButton>
            <SolidBtn
              onClick={() => form.submit()}
              type="primary"
              style={{ padding: '8px 20px' }}
              disabled={isFormInvalid}
            >
              {t('Save')}
            </SolidBtn>
          </Space>
        </Flex>
      );
    }

    return (
      <SolidBtn
        onClick={handleClose}
        type="primary"
        style={{ padding: '8px 20px' }}
      >
        {t('Close')}
      </SolidBtn>
    );
  };

  const langOptions = [
    {
      label: t('English'),
      value: 'en',
    },
    {
      label: t('Vietnamese'),
      value: 'vn',
    },
  ];

  const handleChange = (value: string, index: number) => {
    const targetGuidelines = guideLang === 'en' ? guidelinesEn : guidelinesVi;
    const newGuidelines = structuredClone(targetGuidelines);

    newGuidelines[index - 1].content = value;

    if (guideLang === 'en') setGuidelinesEn(newGuidelines);
    else setGuidelinesVi(newGuidelines);
  };

  const handleDelete = (index: number) => {
    const targetGuidelines = guideLang === 'en' ? guidelinesEn : guidelinesVi;
    const newGuidelines = structuredClone(targetGuidelines);

    newGuidelines.splice(index - 1, 1);
    if (guideLang === 'en') setGuidelinesEn(newGuidelines);
    else setGuidelinesVi(newGuidelines);
  };

  return (
    <CustomModal
      open={open}
      centered
      footer={getFooter()}
      title={t(isEdit ? 'Update guideline content' : 'Chatbot user guide')}
      width={900}
      onClose={handleClose}
      onCancel={handleClose}
    >
      <Form form={form} onFinish={() => toggleConfirmPublishModal(true)}>
        <Flex vertical gap={16} align="center">
          <Typography.Text style={{ textAlign: 'center' }}>
            {t(
              isEdit
                ? 'Please update the guideline content for both the English and Vietnamese versions'
                : 'The chatbot can assist you quickly with the following tasks. If you encounter any issues while using the chatbot, please contact the support team for assistance.',
            )}
          </Typography.Text>
          {isEdit && (
            <CustomSegmented
              options={langOptions.map((item) => ({
                label: (
                  <span
                    style={{
                      color: item.value === guideLang ? '#F5222D' : '#262626',
                      display: 'flex',
                      padding: 5,
                      fontWeight: 500,
                    }}
                  >
                    {capitalize(item.label)}
                  </span>
                ),
                value: item.value,
              }))}
              defaultValue={lang}
              onChange={(val) => setGuideLang(val as LanguageType)}
            />
          )}
          <List
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            style={{
              maxHeight: 490,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            dataSource={dataSource}
            loading={isLoading}
            renderItem={(item, index) => (
              <List.Item key={item.id} style={{ minWidth: 400 }}>
                <GuideItem
                  isEdit={isEdit}
                  item={item}
                  onChange={handleChange}
                  onDelete={handleDelete}
                  index={index + 1}
                />
              </List.Item>
            )}
          />
        </Flex>
      </Form>
      <CustomModal
        width={400}
        onOk={handleDraft}
        onCancel={() => toggleConfirmDraftModal(false)}
        open={confirmDraftModalOpen}
        title={
          <div style={{ textAlign: 'start' }}>
            {t('Save draft confirmation')}
          </div>
        }
        cancelButtonProps={{
          onClick: () => {
            cancelDraftGuideline()
              .then((res) => {
                toggleLoading(false);
                toggleOpen(false);

                return res;
              })
              .catch((err) => {
                console.log(err);

                toggleLoading(false);
              });
          },
        }}
        centered
      >
        {t('Do you want to save this draft? You can continue editing later.')}
      </CustomModal>
      <CustomModal
        width={400}
        onOk={handlePublish}
        onCancel={() => toggleConfirmPublishModal(false)}
        open={confirmPublishModalOpen}
        title={
          <div style={{ textAlign: 'start' }}>{t('Publish confirmation')}</div>
        }
        centered
      >
        {t(
          'Are you sure you want to save this content and publish it? Once published, the content will be applied across the system.',
        )}
      </CustomModal>
    </CustomModal>
  );
}
