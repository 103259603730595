import type { LoginResDTO } from 'types';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface IAuthState {
  user: LoginResDTO | null;
  setUser: (user: LoginResDTO | null) => void;
  resetUser: () => void;
}

export const useAuthStore = create<IAuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        setUser: (user) => set({ user }),
        resetUser: () => set({ user: null }),
      }),
      {
        name: 'auth-storage',
      },
    ),
  ),
);
